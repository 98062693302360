import React from 'react'
import { Helmet } from "react-helmet";
import axios from 'axios';
import { useState, useEffect } from 'react';
import { NavLink, useParams } from "react-router-dom";
import './BrandCoupons.css';
import { Base_API_URL } from '../API.js';
import educative from '../images/educative.png';
import nasco from '../images/nasco.png';
import slumberKins from '../images/slumberKins.png';
import hooked from '../images/hooked.png';
import science from '../images/science.png';
import mcKissock from '../images/mcKissock.png';
import nest from '../images/nest.png';
import nitro from '../images/nitro.png';
import genius from '../images/genius.png';
import hero from '../images/hero.png';
import LoadingBar from 'react-top-loading-bar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Collapse from 'react-bootstrap/Collapse';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function BrandCoupons() {
    //window.scrollTo(0, 0)
    const parameter = useParams();
    //console.log(parameter.parameter);

    const d = new Date();
    let year = d.getFullYear().toString();

    const [open, setOpen] = useState({});
    const [show, setShow] = useState({});
    const [progress, setProgress] = useState(50);
    const [count, setCount] = useState(0);
    const [lstcoupons, setcoupons] = useState([]);
    useEffect(() => {
        setProgress(50)
        axios.get(Base_API_URL+"/api/Home/GetEducationCouponCodes?Name=" + parameter.parameter)
            .then(response => {
                setcoupons(response.data)
                setProgress(100)
            })
            .catch(err => {
                //console.log(err);
                setProgress(100)
            })
    }, [count]);
    //console.log(lstcoupons);
    var brand = '';
    if (lstcoupons.length > 0) {
        brand = lstcoupons[0].name.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '');
        //console.log(brand);
    }

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    var newUrl = currentUrl.slice(0, -1);
    if (lastElement == "/") {
        window.history.pushState({}, null, newUrl);
    }

    const copyCode = (e) => {
        let code = e.currentTarget.id;
        //document.querySelector("#accordionExample #helpbutton").style.display = "block";
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(code);
        /* Alert the copied text */
        //alert("Copied the text: " + code);
        //document.querySelector("#accordionExample #helpbutton").style.display = "none";
    }

    const handleCollapse = (id) => {
        setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));
    }

    const handleModal = (id) => {
        setShow((prevState) => ({ ...prevState, [id]: !prevState[id] }));
    }

    const handleClose = () => setShow(false);

    return (
        <>
            <LoadingBar color='orangered' progress={progress} height={5} />
            <Helmet>
                <title>{brand + ' Coupons, Discount & Promo Codes ' + year}</title>
                <meta name="description" content={brand + ` Coupons And Promos`} />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <br />
            <div className="container">
                <div className="row justify-content-center text-center bg-white pt-2" style={{ boxShadow: '0 0 11px rgba(33,33,33,.3)', textShadow: '1px 1px 5px #A11B61' }}>
                    <h4 className="font-weight-bold">{brand} Coupons</h4>
                </div>
                <br />
                {
                    lstcoupons == null || lstcoupons.length == 0 ? ""
                        : <div className="row" id="card">
                            <div className="col-md-9 p-0" id="accordionExample">
                                {
                                    lstcoupons.map((ind, i) => {
                                        return (
                                            <>
                                                <div className="col-12" i={i}>
                                                    <div className="card mb-3 bg-white" style={{ width: '100%', fontFamily: 'Times New Roman' }}>
                                                        <div className="row no-gutters">
                                                            <div className="col-4 col-md-2 mt-auto mb-auto text-center">
                                                                {
                                                                    // brand.includes("Educative") ?
                                                                    //     <LazyLoadImage effect="blur" src={educative} className="card-img-top" style={{ width: '100%' }} />
                                                                    //     : brand.includes("Nasco") ?
                                                                    //         <LazyLoadImage effect="blur" src={nasco} className="card-img-top" style={{ width: '100%' }} />
                                                                    //         : brand.includes("Slumberkins") ?
                                                                    //             <LazyLoadImage effect="blur" src={slumberKins} className="card-img-top" style={{ width: '100%' }} />
                                                                    //             : brand.includes("Hooked") ?
                                                                    //                 <LazyLoadImage effect="blur" src={hooked} className="card-img-top" style={{ width: '100%' }} />
                                                                    //                 : brand.includes("Chemistry") ?
                                                                    //                     <LazyLoadImage effect="blur" src={science} className="card-img-top" style={{ width: '100%' }} />
                                                                    //                     : brand.includes("Mckissock") ?
                                                                    //                         <LazyLoadImage effect="blur" src={mcKissock} className="card-img-top" style={{ width: '100%' }} />
                                                                    //                         : brand.includes("Nestlearning") ?
                                                                    //                             <LazyLoadImage effect="blur" src={nest} className="card-img-top" style={{ width: '100%' }} />
                                                                    //                             : brand.includes("Nitro") ?
                                                                    //                                 <LazyLoadImage effect="blur" src={nitro} className="card-img-top" style={{ width: '100%' }} />
                                                                    //                                 : brand.includes("Genius") ?
                                                                    //                                     <LazyLoadImage effect="blur" src={genius} className="card-img-top" style={{ width: '100%' }} />
                                                                    //                                     : <LazyLoadImage effect="blur" src={hero} className="card-img-top" style={{ width: '100%' }} />
                                                                    <h5 className='font-weight-bold mb-0'>{ind.freeOff}</h5>
                                                                }
                                                            </div>
                                                            <div className="col-8 col-md-7 mt-auto mb-auto border-left">
                                                                <div className="card-body text-md-left text-center p-3">
                                                                    <small className="text-danger font-weight-bold">{ind.type}</small>
                                                                    <br />
                                                                    {
                                                                        ind.code != "No Code Required!" && ind.code != null ?
                                                                            <NavLink className="card-title font-weight-bold mb-1 text-md-left text-center" onClick={(e) => handleModal(ind.id)} style={{ textShadow: '1px 1px 5px #A11B61', cursor: 'pointer', textDecoration: 'none' }}>{ind.title.replace("&quot;", "").replace("&amp;", "")}</NavLink>
                                                                            :
                                                                            <NavLink className="card-title font-weight-bold mb-1 text-md-left text-center" style={{ textShadow: '1px 1px 5px #A11B61', cursor: 'pointer', textDecoration: 'none' }}>{ind.title.replace("&quot;", "").replace("&amp;", "")}</NavLink>
                                                                    }
                                                                    <p className="card-text mb-0 text-md-left text-center" style={{ cursor: 'pointer', fontSize: '15px' }}>{brand + ' ' + ind.type}</p>
                                                                    <b className="card-text mb-0 text-md-left text-center" style={{ cursor: 'pointer', fontSize: '15px' }}>{ind.verified + ' | ' + ind.users}</b>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-md-3 mt-md-auto mb-md-auto mb-3 text-center">
                                                                {
                                                                    ind.code != "No Code Required!" && ind.code != null ?
                                                                        <button className="btn font-weight-bold btn-primary" onClick={(e) => handleModal(ind.id)} id="butn" style={{ color: 'white' }}>Show Coupon Code</button>
                                                                        :
                                                                        <button className="btn font-weight-bold btn-primary" onClick={(e) => handleModal(e)} id="btn" style={{ color: 'white', width: '163px' }}>Get Deals</button>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div style={{ backgroundColor: '#F1F1F1' }} className="p-2">
                                                            <a className="p-4 text-dark arow" onClick={() => handleCollapse(ind.id)} aria-expanded={open} aria-controls={ind.id}>
                                                                Promo Details <i className="fa fa-chevron-down" aria-hidden="true"></i>
                                                            </a>
                                                        </div>
                                                        <Collapse in={open[ind.id]} timeout="auto" unmountOnExit>
                                                            <div className="card card-body">
                                                                {
                                                                    ind.detail != "" && ind.detail != null ?
                                                                        <span><b>Details: </b>{ind.detail}
                                                                            <Button className="close ml-auto" onClick={() => setOpen(false)} aria-label="Close">
                                                                                <span aria-hidden="true">&times;</span>
                                                                            </Button>
                                                                        </span>
                                                                        :
                                                                        <span>No Details Available For Now!
                                                                            <button className="close ml-auto" onClick={() => setOpen(false)} aria-label="Close">
                                                                                <span aria-hidden="true">&times;</span>
                                                                            </button>
                                                                        </span>

                                                                }
                                                            </div>
                                                        </Collapse>
                                                    </div>
                                                </div>
                                                <Modal show={show[ind.id]} onHide={handleClose}>
                                                    <Modal.Header>
                                                        <div className='ml-auto'>
                                                            {
                                                                brand.includes("Educative") ?
                                                                    <LazyLoadImage effect="blur" src={educative} className="card-img-top" />
                                                                    : brand.includes("Nasco") ?
                                                                        <LazyLoadImage effect="blur" src={nasco} className="card-img-top" />
                                                                        : brand.includes("Slumberkins") ?
                                                                            <LazyLoadImage effect="blur" src={slumberKins} className="card-img-top" />
                                                                            : brand.includes("Hooked") ?
                                                                                <LazyLoadImage effect="blur" src={hooked} className="card-img-top" />
                                                                                : brand.includes("Chemistry") ?
                                                                                    <LazyLoadImage effect="blur" src={science} className="card-img-top" />
                                                                                    : brand.includes("Mckissock") ?
                                                                                        <LazyLoadImage effect="blur" src={mcKissock} className="card-img-top" />
                                                                                        : brand.includes("Nestlearning") ?
                                                                                            <LazyLoadImage effect="blur" src={nest} className="card-img-top" />
                                                                                            : brand.includes("Nitro") ?
                                                                                                <LazyLoadImage effect="blur" src={nitro} className="card-img-top" />
                                                                                                : brand.includes("Genius") ?
                                                                                                    <LazyLoadImage effect="blur" src={genius} className="card-img-top" />
                                                                                                    : <LazyLoadImage effect="blur" src={hero} className="card-img-top" />
                                                            }
                                                        </div>
                                                        <Button className="ml-auto" onClick={handleClose} aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </Button>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <h5 className="text-primary text-dark text-center" style={{ textShadow: '1px 1px 5px #A11B61' }}>{ind.title.replace("&quot;", "").replace("&amp;", "")}</h5>
                                                        <br />
                                                        <div className="form-inline">
                                                            {
                                                                ind.code != "No Code Required!" && ind.code != null ?
                                                                    <>
                                                                        <input type="text" className="form-control ml-auto mr-auto mb-md-0 mb-2" value={ind.code} />
                                                                        <button class="btn btn-info mr-auto ml-auto" onClick={(e) => copyCode(e)} id={ind.code}>Copy Code</button>
                                                                        <span id="helpbutton" style={{ borderRadius: '30px', display: 'none' }} className="bg-danger text-white p-2">Copied</span>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <input type="text" className="form-control ml-auto mr-auto mb-md-0 mb-2" value="No Code Required!" />
                                                                    </>
                                                            }
                                                        </div>
                                                        <br />
                                                        {
                                                            ind.detail != "" ?
                                                                <small><b>Details: </b>{ind.detail}</small>
                                                                : ""
                                                        }
                                                    </Modal.Body>
                                                </Modal>
                                            </>
                                        )
                                    })
                                }
                            </div>
                            <div className="col-md-3">
                                <div className="border" style={{ paddingTop: '10%', paddingBottom: '10%', boxShadow: '0 0 11px rgba(33,33,33,.1)' }}>
                                    <h4 style={{ textShadow: '1px 1px 5px #A11B61' }} className="text-center"><b>Related Brands</b></h4>
                                    <ul className="bg-white">
                                        <br />
                                        <li><NavLink to="/coupons/educative/" onClick={() => setCount((c) => c + 1)}>Educative.io</NavLink></li>
                                        <br />
                                        <li><NavLink to="/coupons/nasco/" onClick={() => setCount((c) => c + 1)}>Nasco</NavLink></li>
                                        <br />
                                        <li><NavLink to="/coupons/slumberkins/" onClick={() => setCount((c) => c + 1)}>SlumberKins</NavLink></li>
                                        <br />
                                        <li><NavLink to="/coupons/hooked-on-phonics/" onClick={() => setCount((c) => c + 1)}>Hooked On Phonics</NavLink></li>
                                        <br />
                                        <li><NavLink to="/coupons/mel-chemistry/" onClick={() => setCount((c) => c + 1)}>Mel Science</NavLink></li>
                                        <br />
                                        <li><NavLink to="/coupons/mckissock/" onClick={() => setCount((c) => c + 1)}>McKissock</NavLink></li>
                                        <br />
                                        <li><NavLink to="/coupons/nestlearning/" onClick={() => setCount((c) => c + 1)}>Nest Learning</NavLink></li>
                                        <br />
                                        <li><NavLink to="/coupons/nitro-college/" onClick={() => setCount((c) => c + 1)}>Nitro College</NavLink></li>
                                        <br />
                                        <li><NavLink to="/coupons/genius-one/" onClick={() => setCount((c) => c + 1)}>Genius One</NavLink></li>
                                        <br />
                                        <li><NavLink to="/coupons/course-hero/" onClick={() => setCount((c) => c + 1)}>Course Hero</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                }
                <br />
                {
                    lstcoupons != null && lstcoupons.length > 0 ?
                        <div className="row">
                            <div className="col-12">
                                <table className="table table-bordered table-striped table-responsive-md table-hover">
                                    <thead>
                                        <tr className="bg-danger text-white">
                                            <th>Brand Name</th>
                                            <th>Offers</th>
                                            <th>Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            lstcoupons.length > 4 ?
                                                lstcoupons.filter((i, index) => (index < 5)).map((x, k) => {
                                                    return (
                                                        <tr k={k}>
                                                            <td>{x.name.replace(/ .*/, '').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '').replace(/-/g, ' ')}</td>
                                                            <td>{x.title.replace("&quot;", "").replace("&amp;", "&")}</td>
                                                            <td>{x.type.replace("Expires On : ", "").replace("Expired On : ", "")}</td>
                                                        </tr>
                                                    )
                                                })
                                                : lstcoupons.length > 3 ?
                                                    lstcoupons.filter((i, index) => (index < 4)).map((x, k) => {
                                                        return (
                                                            <tr k={k}>
                                                                <td>{x.name.replace(/ .*/, '').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '').replace(/-/g, ' ')}</td>
                                                                <td>{x.title.replace("&quot;", "").replace("&amp;", "&")}</td>
                                                                <td>{x.type.replace("Expires On : ", "").replace("Expired On : ", "")}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    : lstcoupons.length > 2 ?
                                                        lstcoupons.filter((i, index) => (index < 3)).map((x, k) => {
                                                            return (
                                                                <tr k={k}>
                                                                    <td>{x.name.replace(/ .*/, '').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '').replace(/-/g, ' ')}</td>
                                                                    <td>{x.title.replace("&quot;", "").replace("&amp;", "&")}</td>
                                                                    <td>{x.type.replace("Expires On : ", "").replace("Expired On : ", "")}</td>
                                                                </tr>
                                                            )
                                                        })
                                                        : lstcoupons.length > 1 ?
                                                            lstcoupons.filter((i, index) => (index < 2)).map((x, k) => {
                                                                return (
                                                                    <tr k={k}>
                                                                        <td>{x.name.replace(/ .*/, '').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '').replace(/-/g, ' ')}</td>
                                                                        <td>{x.title.replace("&quot;", "").replace("&amp;", "&")}</td>
                                                                        <td>{x.type.replace("Expires On : ", "").replace("Expired On : ", "")}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                            : ""
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        : ""
                }
            </div>
            <br />
        </>
    )
}