import React from 'react'
import '../App.css'

export default function Footer() {
  const d = new Date();
  let year = d.getFullYear();

  let top = {
    display: 'none',
    position: 'fixed',
    bottom: '70px',
    right: '30px',
    zIndex: '99',
    fontSize: '18px',
    backgroundColor: '#1b1834',
    color: 'white',
    cursor: 'pointer',
    padding: '15px 20px 15px 20px',
    borderRadius: '50px',
  }

  window.onscroll = function () { scrollFunction() };
  function scrollFunction() {
    if (document.body.scrollTop > 700 || document.documentElement.scrollTop > 700) {
      document.getElementById("top").style.display = "block";
    } else {
      document.getElementById("top").style.display = "none";
    }
  }

  function scrollTop() {
    window.scrollTo(0, 0)
  }

  return (
    <>
      <br /><br />
      <a id='top' onClick={scrollTop} title="Go To Top" style={top}><i className="fa fa-arrow-up" aria-hidden="true"></i></a>
      <footer className="border-top footer text-muted" style={{backgroundColor: '#1b1834'}}>
        <div className="container">
          <div className="row justify-content-center text-white">
            <small className='font-weight-bold'>Copyright © {year} All Rights Reserved</small>
          </div>
        </div>
      </footer>
    </>
  )
}
