import React from 'react'
import { Helmet } from "react-helmet";
import { useParams, NavLink } from "react-router-dom";
import { useState, useEffect } from 'react';
import axios from "axios";
import { Base_API_URL } from '../API.js';
import './CourseDetails.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import course1 from '../images/course1.png';
import course2 from '../images/course2.png';
import course3 from '../images/course3.png';
import course4 from '../images/course4.png';
import course5 from '../images/course5.png';
import courses from '../images/courses.png';
import certificate from '../images/certificate.jpg';
import _404Error from '../images/_404Error.png';
import courses1 from '../images/courses1.png';
import LoadingBar from 'react-top-loading-bar'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function CourseDetails() {
    window.scrollTo(0, 0)
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    var newUrl = currentUrl.slice(0, -1);
    if (lastElement == "/") {
        window.history.pushState({}, null, newUrl);
    }

    const parameter1 = useParams();
    //console.log(parameter1.parameter2);
    if (parameter1.parameter2 == "topics") {
        window.location.href = '/' + parameter1.parameter1 + '/'
    }

    const [show, setShow] = useState(false);
    const [progress, setProgress] = useState(50)
    const [country, setcountry] = useState([]);
    const [coursedetails, setcoursesdetails] = useState([]);
    const [coursedetailserr, setcoursesdetailserr] = useState(0);
    const [relatedcourses, setrelatedcourses] = useState([]);
    const [relatedcourseserr, setrelatedcourseserr] = useState(0);
    const [count, setCount] = useState(0);
    useEffect(() => {
        if (parameter1.parameter1 == "educative") {
            axios.get(Base_API_URL+"/api/Home/GetEducativeCoursesDetails?Name=" + parameter1.parameter2)
                .then(response => {
                    if (response.data.length > 0) {
                        setcoursesdetails(response.data)
                        setProgress(100)
                    }
                    else {
                        setcoursesdetailserr(1);
                    }
                })
                .catch(err => {
                    //console.log(err);
                    setcoursesdetailserr(1);
                })

            axios.get(Base_API_URL+"/api/Home/GetRelatedEducativeCoursesName?Name=" + parameter1.parameter2)
                .then(response => setrelatedcourses(response.data))
                .catch(err => {
                    //console.log(err);
                    setrelatedcourseserr(1);
                })
        } else if (parameter1.parameter1 == "pluralsight") {
            axios.get(Base_API_URL+"/api/Home/GetPluralsightCoursesDetails?Name=" + parameter1.parameter2)
                .then(response => {
                    if (response.data.length > 0) {
                        setcoursesdetails(response.data)
                        setProgress(100)
                    } else {
                        setcoursesdetailserr(1);
                    }
                })
                .catch(err => {
                    //console.log(err);
                    setcoursesdetailserr(1);
                })

            axios.get(Base_API_URL+"/api/Home/GetRelatedPluralsightCourses?Name=" + parameter1.parameter2)
                .then(response => setrelatedcourses(response.data))
                .catch(err => {
                    //console.log(err);
                    setrelatedcourseserr(1);
                })
        } else if (parameter1.parameter1 == "skillshare") {
            axios.get(Base_API_URL+"/api/Home/GetSkillShareCoursesDetails?Name=" + parameter1.parameter2)
                .then(response => {
                    if (response.data.length > 0) {
                        setcoursesdetails(response.data)
                        setProgress(100)
                    } else {
                        setcoursesdetailserr(1);
                    }
                })
                .catch(err => {
                    //console.log(err);
                    setcoursesdetailserr(1);
                })

            axios.get(Base_API_URL+"/api/Home/GetRelatedSkillShareCourses?Name=" + parameter1.parameter2)
                .then(response => setrelatedcourses(response.data))
                .catch(err => {
                    //console.log(err);
                    setrelatedcourseserr(1);
                })
        } else if (parameter1.parameter1 == "freecodecamp") {
            axios.get(Base_API_URL+"/api/Home/GetFreeCodecampCoursesDetails?Name=" + parameter1.parameter2)
                .then(response => {
                    if (response.data.length > 0) {
                        setcoursesdetails(response.data)
                        setProgress(100)
                    } else {
                        setcoursesdetailserr(1);
                    }
                })
                .catch(err => {
                    //console.log(err);
                    setcoursesdetailserr(1);
                })
        }
    }, [count]);
    //console.log(coursedetails);
    //console.log(country);

    const fname = (e) => {
        var fname = e.target.value;
        document.getElementById("fname").style.textTransform = "capitalize";
        var lname = document.getElementById("lname").value;
        var email = document.getElementById("email").value;
        var phone = document.getElementById("phone").value;
        var country = document.getElementById("country").value;
        var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        if (fname == "" || lname == "" || email == "" || phone == "" || country == "") {
            document.getElementById("sendOrder").classList.add('disabled');
        } else if (!filter.test(email)) {
            document.getElementById("sendOrder").classList.add('disabled');
        } else {
            document.getElementById("sendOrder").classList.remove('disabled');
        }
    }

    const lname = (e) => {
        var lname = e.target.value;
        document.getElementById("lname").style.textTransform = "capitalize";
        var fname = document.getElementById("fname").value;
        var email = document.getElementById("email").value;
        var phone = document.getElementById("phone").value;
        var country = document.getElementById("country").value;
        var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        if (fname == "" || lname == "" || email == "" || phone == "" || country == "") {
            document.getElementById("sendOrder").classList.add('disabled');
        } else if (!filter.test(email)) {
            document.getElementById("sendOrder").classList.add('disabled');
        } else {
            document.getElementById("sendOrder").classList.remove('disabled');
        }
    }

    const email = (e) => {
        var email = e.target.value;
        var fname = document.getElementById("fname").value;
        var lname = document.getElementById("lname").value;
        var phone = document.getElementById("phone").value;
        var country = document.getElementById("country").value;
        var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        if (fname == "" || lname == "" || email == "" || phone == "" || country == "") {
            document.getElementById("sendOrder").classList.add('disabled');
        } else if (!filter.test(email)) {
            document.getElementById("sendOrder").classList.add('disabled');
        } else {
            document.getElementById("sendOrder").classList.remove('disabled');
        }
    }

    const phone = (e) => {
        var phone = e.target.value;
        var fname = document.getElementById("fname").value;
        var lname = document.getElementById("lname").value;
        var email = document.getElementById("email").value;
        var country = document.getElementById("country").value;
        var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        if (fname == "" || lname == "" || email == "" || phone == "" || country == "") {
            document.getElementById("sendOrder").classList.add('disabled');
        } else if (!filter.test(email)) {
            document.getElementById("sendOrder").classList.add('disabled');
        } else {
            document.getElementById("sendOrder").classList.remove('disabled');
        }
    }

    const countryy = (e) => {
        var country = e.target.value;
        var fname = document.getElementById("fname").value;
        var lname = document.getElementById("lname").value;
        var email = document.getElementById("email").value;
        var phone = document.getElementById("phone").value;
        var filter = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
        if (fname == "" || lname == "" || email == "" || phone == "" || country == "") {
            document.getElementById("sendOrder").classList.add('disabled');
        } else if (!filter.test(email)) {
            document.getElementById("sendOrder").classList.add('disabled');
        } else {
            document.getElementById("sendOrder").classList.remove('disabled');
        }
    }

    function sendOrder() {
        var coursename = document.getElementById("coursename").value;
        var fname = document.getElementById("fname").value;
        var lname = document.getElementById("lname").value;
        var email = document.getElementById("email").value;
        var phone = document.getElementById("phone").value;
        var country = document.getElementById("country").value;

        if (fname == "" || lname == "" || email == "" || phone == "" || country == "") {
            return false;
        }

        var obj = {
            CourseName: coursename,
            First_Name: fname,
            Last_Name: lname,
            Email: email,
            Mobile: phone,
            Country: country
        }

        let json = JSON.stringify(obj);
        fetch(Base_API_URL+'/api/Home/InsertOrderCourseForm', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: json
        }).then(response => {
            setShow(false)
            //console.log(response.ok);
            if (response.ok == true) {
                confirmAlert({
                    title: "Success",
                    message: "Your Order Is Submit Successfully",
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => {
                                window.location.reload();
                            }
                        }
                    ]
                });
            } else {
                confirmAlert({
                    title: "Error",
                    message: "Failed To Submit Order",
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => { }
                        }
                    ]
                });
                return;
            }
        }).catch(error => {
            confirmAlert({
                title: "Error",
                message: error,
                buttons: [
                    {
                        label: 'OK',
                        onClick: () => { }
                    }
                ]
            });
            return;
        });
    }

    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        e.preventDefault();
        axios.get(Base_API_URL+"/api/Home/GetCountryName")
            .then(response => {
                setcountry(response.data)
            })
            .catch(err => {
                //console.log(err);
            })
        setShow(true)
    }

    const d = new Date();
    let year = d.getFullYear().toString();

    if (parameter1.parameter1 == "designgurus") {
        if (parameter1.parameter2 == "grokking-dynamic-programming-patterns-for-coding-interviews") {
            return (
                <>
                    <LoadingBar color='orangered' progress={100} height={5} />
                    <Helmet>
                        <title>{parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')}  {year}</title>
                        <meta property="og:title" content={parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '') + ' - ' + year} />
                        <meta name='description' content='Dynamic Programming (DP) challenges can in reality be among the most challenging during a coding interview. Even when it is actually obvious whether a problem can be solved using DP (which is exceptional), it can be problematic to even start the solution. Unless you have received training in how to solve DP challenges.' />
                        <meta property="og:description" content="Dynamic Programming (DP) challenges can in reality be among the most challenging during a coding interview. Even when it is actually obvious whether a problem can be solved using DP (which is exceptional), it can be problematic to even start the solution. Unless you have received training in how to solve DP challenges." />
                        <link rel="canonical" href={window.location.href} />
                    </Helmet>
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className='col-12'>
                                <div className='row justify-content-center' style={{ paddingTop: '5%', paddingBottom: '4%', background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>
                                    <div className="col-md-5 mb-md-0 mb-4">
                                        <LazyLoadImage effect="blur" src={course4} className="img-fluid" style={{ width: '80%' }} alt="course4" />
                                    </div>
                                    <div className="col-md-5 my-auto text-center text-white text-md-left">
                                        <h1 className="learnworlds-subheading-large">Grokking Dynamic Programming Patterns for Coding Interviews</h1>
                                        <p style={{ textShadow: '1px 1px 1px red' }}>Faster preparation for coding interviews.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-md-auto mt-3">
                                <div className="row mt-3" id="icons">
                                    <div className="col-auto">
                                        <i className="fa fa-graduation-cap text-danger"></i> &nbsp; Author: Coding Courses Hub
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-line-chart text-danger"></i> &nbsp; Level: Beginner
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-clock-o text-danger"></i> &nbsp; Study time: 18h
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-lock text-danger"></i> &nbsp; Lessons: 37
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-bar-chart text-danger"></i> &nbsp; Illustrations: 254
                                    </div>
                                </div>
                                <br />
                                <h3 className="learnworlds-subheading-large">Course Overview</h3>
                                <br />
                                <p>Dynamic Programming (DP) challenges can in reality be among the most challenging during a coding interview. Even when it is actually obvious whether a problem can be solved using DP (which is exceptional), it can be problematic to even start the solution. Unless you have received training in how to solve DP challenges.</p>
                                <br />
                                <p>
                                    Just that idea was the primary driver for the creation of this course. It will give you a collection of simple solutions to solve any DP issue. Instead of just asking you to try to memorise answers, you will be guided through five fundamental DP patterns that can be used to address 35+ DP problems. We'll begin each pattern with a recursive brute-force -- Actually, this is the ideal technique to start resolving a DP issue! The advanced DP methods of Memoization and Tabulation will then be used after we have a recursive solution.
                                </p>
                                <p>
                                    The exercise problems in this course were chosen with consideration, covering the DP questions that are usually asked in coding interviews
                                </p>
                            </div>
                            <div className="col-md-4 pt-4" style={{ backgroundColor: '#E9E9E9' }}>
                                <div className="row justify-content-center">
                                    <div className="col-auto">
                                        <h3>Lifetime Access</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-auto">
                                        <u><b>CERTIFICATION INCLUDED</b></u>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-auto">
                                        <p>Certification on completing the course.</p>
                                    </div>
                                </div>
                                <LazyLoadImage effect="blur" src={certificate} style={{ width: '100%' }} />
                                <br /><br />
                                <div className="row">
                                    <div className="col-auto">
                                        <u><b>UNLIMITED ACCESS</b></u>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-auto">
                                        <p>Lifetime access including all future updates.</p>
                                    </div>
                                </div>
                                <div className="row bg-white m-2 pr-3 py-4">
                                    <div className="col-12 text-center mb-2">
                                        <b>PAY ONCE</b>
                                        <br />
                                        <span>Free</span>
                                    </div>
                                    <div className="col-12">
                                        <button onClick={(e) => handleShow(e)} className="btn btn-outline-danger btn-block">Enroll</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container guruscourses'>
                        <div className='row'>
                            <div className="col-12 mt-4 border-top">
                                <br />
                                <h3 className="learnworlds-subheading-large">Course Contents</h3>
                                <br />
                                <div className="row c1">
                                    <div className="col-1 border-right">
                                        <b>01</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Introduction</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp; &nbsp;
                                            What is Dynamic Programming?

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c2">
                                    <div className="col-1 border-right">
                                        <b>02</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Pattern 1: 0/1 Knapsack</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            0/1 Knapsack

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Equal Subset Sum Partition

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Subset Sum

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Minimum Subset Sum Difference

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Count of Subset Sum

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Target Sum

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c3">
                                    <div className="col-1 border-right">
                                        <b>03</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Pattern 2: Unbounded Knapsack</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Unbounded Knapsack

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Rod Cutting

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Coin Change

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Minimum Coin Change

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Maximum Ribbon Cut

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c4">
                                    <div className="col-1 border-right">
                                        <b>04</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Pattern 3: Fibonacci Numbers</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Fibonacci numbers

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Staircase

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Number factors

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Minimum jumps to reach the end

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Minimum jumps with fee

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            House thief

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c5">
                                    <div className="col-1 border-right">
                                        <b>05</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Pattern 4: Palindromic Subsequence</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Longest Palindromic Subsequence

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Longest Palindromic Substring

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Count of Palindromic Substrings

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Minimum Deletions in a String to make it a Palindrome

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Palindromic Partitioning

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c6">
                                    <div className="col-1 border-right">
                                        <b>06</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Pattern 5: Longest Common Substring</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Longest Common Substring

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Longest Common Subsequence

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Minimum Deletions & Insertions to Transform a String into another

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Longest Increasing Subsequence

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Maximum Sum Increasing Subsequence

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Shortest Common Super-sequence

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Minimum Deletions to Make a Sequence Sorted

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Longest Repeating Subsequence

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Subsequence Pattern Matching

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Longest Bitonic Subsequence

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Longest Alternating Subsequence

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Edit Distance

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Strings Interleaving

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c7">
                                    <div className="col-1 border-right">
                                        <b>07</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Certificate</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-certificate text-danger"></i> &nbsp;
                                            Certificate
                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c8">
                                    <div className="col-1 border-right">
                                        <b>08</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Appendix</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Contact Us

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />

                    {/* Modal */}
                    <Modal show={show}>
                        <Modal.Body style={{background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145', color: 'white'}}>
                            <Form>
                                <Form.Control type="text" id="coursename" hidden value="Grokking Dynamic Programming Patterns for Coding Interviews" />
                                <Form.Group className="mb-3" controlId="fname">
                                    <Form.Label>First Name <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control type="text" onKeyUp={(e) => fname(e)} id="fname" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="lname">
                                    <Form.Label>Last Name <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control type="text" onKeyUp={(e) => lname(e)} id="lname" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control type="email" onKeyUp={(e) => email(e)} id="email" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="phone">
                                    <Form.Label>Phone # <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control type="number" onKeyUp={(e) => phone(e)} id="phone" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="country">
                                    <Form.Label>Country <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control as="select" onChange={(e) => countryy(e)} id="country">
                                        <option value="">Select</option>
                                        {
                                            country.length > 0 ?
                                                country.map((c =>
                                                    <option>{c.countryName}</option>
                                                ))
                                                : ""
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer style={{background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145', color: 'white'}}>
                            <Button variant="secondary" onClick={handleClose}>Close</Button>
                            <Button variant="warning" className='disabled' id="sendOrder" onClick={sendOrder}>Save Changes</Button>
                        </Modal.Footer>
                    </Modal>
                </>
            )
        } else if (parameter1.parameter2 == "grokking-the-system-design-interview") {
            return (
                <>
                    <LoadingBar color='orangered' progress={100} height={5} />
                    <Helmet>
                        <title>{parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')}  {year}</title>
                        <meta property="og:title" content={parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '') + ' - ' + year} />
                        <meta name='description' content='System design questions are now a common occurrence during software engineering interviews. The position and income the interviewing business gives you depend on how well you perform in these interviews, which also reflects on your capacity to work with complicated systems. Unfortunately, most engineers find it difficult to succeed in the system design interview (SDI), in part because they lack experience creating large-scale systems and in part because SDIs are not well-structured. Even engineers with some background in creating such systems find these interviews uncomfortable, mostly because the design challenges are left open-ended and without a predetermined solution.' />
                        <meta property="og:description" content="System design questions are now a common occurrence during software engineering interviews. The position and income the interviewing business gives you depend on how well you perform in these interviews, which also reflects on your capacity to work with complicated systems. Unfortunately, most engineers find it difficult to succeed in the system design interview (SDI), in part because they lack experience creating large-scale systems and in part because SDIs are not well-structured. Even engineers with some background in creating such systems find these interviews uncomfortable, mostly because the design challenges are left open-ended and without a predetermined solution." />
                        <link rel="canonical" href={window.location.href} />
                    </Helmet>
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className='col-12'>
                                <div className='row justify-content-center' style={{ paddingTop: '5%', paddingBottom: '4%', background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>
                                    <div className="col-md-5 mb-md-0 mb-4">
                                        <LazyLoadImage effect="blur" src={course2} className="card-img-top" style={{ width: '80%' }} alt="course2" />
                                    </div>
                                    <div className="col-md-5 my-auto text-center text-white text-md-left">
                                        <h1 className="learnworlds-subheading-large">Grokking the System Design Interview</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-md-auto mt-3">
                                <div className="row mt-3" id="icons">
                                    <div className="col-auto">
                                        <i className="fa fa-graduation-cap text-danger"></i> &nbsp; Author: Coding Courses Hub
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-line-chart text-danger"></i> &nbsp; Level: Beginner
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-clock-o text-danger"></i> &nbsp; Study time: 20h
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-lock text-danger"></i> &nbsp; Lessons: 37
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-bar-chart text-danger"></i> &nbsp; Illustrations: 106
                                    </div>
                                </div>
                                <br />
                                <h3 className="learnworlds-subheading-large">Course Overview</h3>
                                <br />
                                <p>System design questions are now a common occurrence during software engineering interviews. The position and income the interviewing business gives you depend on how well you perform in these interviews, which also reflects on your capacity to work with complicated systems. Unfortunately, most engineers find it difficult to succeed in the system design interview (SDI), in part because they lack experience creating large-scale systems and in part because SDIs are not well-structured. Even engineers with some background in creating such systems find these interviews uncomfortable, mostly because the design challenges are left open-ended and without a predetermined solution.</p>
                                <br />
                                <p>
                                    This course serves as an all-inclusive manual for aceing the System Design Interview. It was made by a group of recruiting managers from Google, Facebook, Microsoft, and Amazon called Coding Courses Hub. We have chosen a set of questions with care since they have not only been used frequently at leading firms but also because they offer a thorough Grokking of how to address any system design issue.
                                </p>
                            </div>
                            <div className="col-md-4 pt-4" style={{ backgroundColor: '#E9E9E9' }}>
                                <div className="row justify-content-center">
                                    <div className="col-auto">
                                        <h3>Lifetime Access</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-auto">
                                        <u><b>CERTIFICATION INCLUDED</b></u>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-auto">
                                        <p>Certification on completing the course.</p>
                                    </div>
                                </div>
                                <LazyLoadImage effect="blur" src={certificate} style={{ width: '100%' }} />
                                <br /><br />
                                <div className="row">
                                    <div className="col-auto">
                                        <u><b>UNLIMITED ACCESS</b></u>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-auto">
                                        <p>Lifetime access including all future updates.</p>
                                    </div>
                                </div>
                                <div className="row bg-white m-2 pr-3 py-4">
                                    <div className="col-12 text-center mb-2">
                                        <b>PAY ONCE</b>
                                        <br />
                                        <span>Free</span>
                                    </div>
                                    <div className="col-12">
                                        <button onClick={(e) => handleShow(e)} className="btn btn-outline-danger btn-block">Enroll</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container guruscourses'>
                        <div className='row'>
                            <div className="col-12 mt-4 border-top">
                                <br />
                                <h3 className="learnworlds-subheading-large">Course Contents</h3>
                                <br />
                                <div className="row c1">
                                    <div className="col-1 border-right">
                                        <b>01</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">System Design Guide</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            System Design Interviews: A step by step guide

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Designing a URL Shortening Service Like TinyURL

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c2">
                                    <div className="col-1 border-right">
                                        <b>02</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">System Design Problems</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Designing Pastebin

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Designing Instagram

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Designing Dropbox

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Designing Facebook Messenger

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Designing Twitter

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Designing Youtube or Netflix

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Designing Typeahead Suggestion

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Designing an API Rate Limiter

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Designing Twitter Search

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Designing Facebook’s Newsfeed

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Designing a Web Crawler

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Designing Yelp or Nearby Friends

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Designing Uber backend

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Designing Ticketmaster

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Additional Resources

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c3">
                                    <div className="col-1 border-right">
                                        <b>03</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Glossary of System Design Basics</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            System Design Basics

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Key Characteristics of Distributed Systems

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Load Balancing

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Caching

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Data Partitioning

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Indexes

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Proxies

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Redundancy and Replication

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            SQL vs. NoSQL

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            CAP Theorem

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            PACELC Theorem (New)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Consistent Hashing (New)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Long-Polling vs WebSockets vs Server-Sent Events

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Bloom Filters (New)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Quorum (New)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Leader and Follower (New)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Heartbeat (New)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Checksum (New)

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c4">
                                    <div className="col-1 border-right">
                                        <b>04</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Appendix</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Contact Us

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Other courses

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-certificate text-danger"></i> &nbsp;
                                            Certificate
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />

                    {/* Modal */}
                    <Modal show={show}>
                        <Modal.Body style={{background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145', color: 'white'}}>
                            <Form>
                                <Form.Control type="text" id="coursename" hidden value="Grokking the System Design Interview" />
                                <Form.Group className="mb-3" controlId="fname">
                                    <Form.Label>First Name <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control type="text" onKeyUp={(e) => fname(e)} id="fname" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="lname">
                                    <Form.Label>Last Name <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control type="text" onKeyUp={(e) => lname(e)} id="lname" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control type="email" onKeyUp={(e) => email(e)} id="email" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="phone">
                                    <Form.Label>Phone # <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control type="number" onKeyUp={(e) => phone(e)} id="phone" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="country">
                                    <Form.Label>Country <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control as="select" onChange={(e) => countryy(e)} id="country">
                                        <option value="">Select</option>
                                        {
                                            country.length > 0 ?
                                                country.map((c =>
                                                    <option>{c.countryName}</option>
                                                ))
                                                : ""
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer style={{background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145', color: 'white'}}>
                            <Button variant="secondary" onClick={handleClose}>Close</Button>
                            <Button variant="warning" className='disabled' id="sendOrder" onClick={sendOrder}>Save Changes</Button>
                        </Modal.Footer>
                    </Modal>
                </>
            )
        } else if (parameter1.parameter2 == "grokking-the-coding-interview-patterns-for-coding-questions") {
            return (
                <>
                    <LoadingBar color='orangered' progress={100} height={5} />
                    <Helmet>
                        <title>{parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')}  {year}</title>
                        <meta property="og:title" content={parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '') + ' - ' + year} />
                        <meta name='description' content='The difficulty of coding interviews is increasing daily. A few years ago, reviewing important data structures and practising 50–75 coding interview questions was more than sufficient interview preparation. Since everyone now has access to enormous collections of code bugs, it is getting harder to account for them. The competition has increased in the process.' />
                        <meta property="og:description" content="The difficulty of coding interviews is increasing daily. A few years ago, reviewing important data structures and practising 50–75 coding interview questions was more than sufficient interview preparation. Since everyone now has access to enormous collections of code bugs, it is getting harder to account for them. The competition has increased in the process." />
                        <link rel="canonical" href={window.location.href} />
                    </Helmet>
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className='col-12'>
                                <div className='row justify-content-center' style={{ paddingTop: '5%', paddingBottom: '4%', background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>
                                    <div className="col-md-5 mb-md-0 mb-4">
                                        <LazyLoadImage effect="blur" src={course1} className="card-img-top" style={{ width: '80%' }} alt="course1" />
                                    </div>
                                    <div className="col-md-5 my-auto text-center text-white text-md-left">
                                        <h1 className="learnworlds-subheading-large">Grokking the Coding Interview: Patterns for Coding Questions</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-md-auto mt-3">
                                <div className="row mt-3" id="icons">
                                    <div className="col-auto">
                                        <i className="fa fa-graduation-cap text-danger"></i> &nbsp; Author: Coding Courses Hub
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-line-chart text-danger"></i> &nbsp; Level: Beginner
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-clock-o text-danger"></i> &nbsp; Study time: 50h
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-lock text-danger"></i> &nbsp; Lessons: 182
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-bar-chart text-danger"></i> &nbsp; Illustrations: 214
                                    </div>
                                </div>
                                <br />
                                <h3 className="learnworlds-subheading-large">Course Overview</h3>
                                <br />
                                <p>The difficulty of coding interviews is increasing daily. A few years ago, reviewing important data structures and practising 50–75 coding interview questions was more than sufficient interview preparation. Since everyone now has access to enormous collections of code bugs, it is getting harder to account for them. The competition has increased in the process.</p>
                                <br />
                                <p>
                                    Our team rapidly came to the conclusion that one skill, "the capacity to map a new problem to an already existing problem," was the one that benefited us the most while we were preparing for coding interviews when we sat down to explore ideas to make the interview process simpler for candidates.
                                    <br />
                                    The difficulty of coding interviews is increasing daily. A few years ago, reviewing important data structures and practising 50–75 coding interview questions was more than sufficient interview preparation. Since everyone now has access to enormous collections of code bugs, it is getting harder to account for them. The competition has increased in the process.
                                </p>
                                <br />
                                <p>
                                    Our team rapidly came to the conclusion that one skill, "the capacity to map a new problem to an already existing problem," was the one that benefited us the most while we were preparing for coding interviews when we sat down to explore ideas to make the interview process simpler for candidates.
                                    <br />
                                    Based on commonalities in the methods required to solve them, we have developed a list of 16 patterns for coding questions to assist candidates. As a result, once you become familiar with a pattern, you can use it to address a wide variety of issues.
                                </p>
                                <br />
                                <p>The methods covered in this course have assisted developers in securing positions at prestigious firms like Google, Facebook, Amazon, and Microsoft.</p>
                            </div>
                            <div className="col-md-4">
                                <div style={{ backgroundColor: '#E9E9E9' }} className='p-4'>
                                    <div className="row justify-content-center">
                                        <div className="col-auto">
                                            <h3>Lifetime Access</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-auto">
                                            <u><b>CERTIFICATION INCLUDED</b></u>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-auto">
                                            <p>Certification on completing the course.</p>
                                        </div>
                                    </div>
                                    <LazyLoadImage effect="blur" src={certificate} style={{ width: '100%' }} />
                                    <br /><br />
                                    <div className="row">
                                        <div className="col-auto">
                                            <u><b>UNLIMITED ACCESS</b></u>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-auto">
                                            <p>Lifetime access including all future updates.</p>
                                        </div>
                                    </div>
                                    <div className="row bg-white m-2 pr-3 py-4">
                                        <div className="col-12 text-center mb-2">
                                            <b>PAY ONCE</b>
                                            <br />
                                            <span>Free</span>
                                        </div>
                                        <div className="col-12">
                                            <button onClick={(e) => handleShow(e)} className="btn btn-outline-danger btn-block">Enroll</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container guruscourses'>
                        <div className='row'>
                            <div className="col-12 mt-4 border-top">
                                <br />
                                <h3 className="learnworlds-subheading-large">Course Contents</h3>
                                <br />
                                <div className="row c1">
                                    <div className="col-1 border-right">
                                        <b>01</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Introduction</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Who should take this course?

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Course Overview

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c2">
                                    <div className="col-1 border-right">
                                        <b>02</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Pattern: Sliding Window</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Maximum Sum Subarray of Size K (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Smallest Subarray with a Greater Sum (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Longest Substring with K Distinct Characters (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Fruits into Baskets (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Longest Substring with Distinct Characters (hard)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Longest Substring with Same Letters after Replacement (hard)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Longest Subarray with Ones after Replacement (hard)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 1

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 2

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 3

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 4

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c3">
                                    <div className="col-1 border-right">
                                        <b>03</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Pattern: Islands (Matrix Traversal) (*NEW*)</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Number of Islands (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Biggest Island (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Flood Fill (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Number of Closed Islands (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 1

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 2

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 3

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c4">
                                    <div className="col-1 border-right">
                                        <b>04</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Pattern: Two Pointers</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Pair with Target Sum (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Separate Duplicates (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Squaring a Sorted Array (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Triplet Sum to Zero (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Triplet Sum Close to Target (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Triplets with Smaller Sum (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Subarrays with Product Less than a Target (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Dutch National Flag Problem (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 1

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 2

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 3

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c5">
                                    <div className="col-1 border-right">
                                        <b>05</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Pattern: Fast & Slow Pointers</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            LinkedList Cycle (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Start of LinkedList Cycle (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Happy Number (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Middle of the LinkedList (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 1

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 2

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 3

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c6">
                                    <div className="col-1 border-right">
                                        <b>06</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Pattern: Merge Intervals</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Merge Intervals (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Insert Interval (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Intervals Intersection (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Conflicting Appointments (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 1

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 2

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 3

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c7">
                                    <div className="col-1 border-right">
                                        <b>07</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Pattern: Cyclic Sort</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Cyclic Sort (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Find the Missing Number (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Find all Missing Numbers (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Find the Duplicate Number (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Find all Duplicate Numbers (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 1

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 2

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 3

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c8">
                                    <div className="col-1 border-right">
                                        <b>08</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Pattern: In-place Reversal of a LinkedList</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Reverse a LinkedList (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Reverse a Sub-list (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Reverse every K-element Sub-list (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 1

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 2

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c9">
                                    <div className="col-1 border-right">
                                        <b>09</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Pattern: Tree Breadth First Search</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Binary Tree Level Order Traversal (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Reverse Level Order Traversal (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Zigzag Traversal (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Level Averages in a Binary Tree (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Minimum Depth of a Binary Tree (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Level Order Successor (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Connect Level Order Siblings (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 1

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 2

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c10">
                                    <div className="col-1 border-right">
                                        <b>10</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Pattern: Tree Depth First Search</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Binary Tree Path Sum (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            All Paths for a Sum (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Sum of Path Numbers (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Path With Given Sequence (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Count Paths for a Sum (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 1

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 2

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c11">
                                    <div className="col-1 border-right">
                                        <b>11</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Pattern: Two Heaps</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Find the Median of a Number Stream (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Sliding Window Median (hard)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Maximize Capital (hard)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 1

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c12">
                                    <div className="col-1 border-right">
                                        <b>12</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Pattern: Subsets</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Subsets (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Subsets With Duplicates (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Permutations (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            String Permutations by changing case (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Balanced Parentheses (hard)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Unique Generalized Abbreviations (hard)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 1

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 2

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 3

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c13">
                                    <div className="col-1 border-right">
                                        <b>13</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Pattern: Modified Binary Search</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Order-agnostic Binary Search (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Ceiling of a Number (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Next Letter (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Number Range (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Search in a Sorted Infinite Array (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Minimum Difference Element (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Bitonic Array Maximum (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 1

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 2

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 3

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c14">
                                    <div className="col-1 border-right">
                                        <b>14</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Pattern: Bitwise XOR</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Single Number (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Two Single Numbers (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Complement of Base 10 Number (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 1

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c15">
                                    <div className="col-1 border-right">
                                        <b>15</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Pattern: Top 'K' Elements</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Top 'K' Numbers (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Kth Smallest Number (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            'K' Closest Points to the Origin (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Connect Ropes (easy)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Top 'K' Frequent Numbers (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Frequency Sort (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Kth Largest Number in a Stream (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            'K' Closest Numbers (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Maximum Distinct Elements (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Sum of Elements (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Rearrange String (hard)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 1

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 2

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 3

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c16">
                                    <div className="col-1 border-right">
                                        <b>16</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Pattern: K-way Merge</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Merge K Sorted Lists (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Kth Smallest Number in M Sorted Lists (Medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Kth Smallest Number in a Sorted Matrix (Hard)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Smallest Number Range (Hard)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 1

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c17">
                                    <div className="col-1 border-right">
                                        <b>17</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Pattern : 0/1 Knapsack (Dynamic Programming)</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            0/1 Knapsack (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Equal Subset Sum Partition (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Subset Sum (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Minimum Subset Sum Difference (hard)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 1

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 2

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c18">
                                    <div className="col-1 border-right">
                                        <b>18</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Pattern: Topological Sort (Graph)</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Topological Sort (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Tasks Scheduling (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Tasks Scheduling Order (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            All Tasks Scheduling Orders (hard)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Alien Dictionary (hard)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 1

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Problem Challenge 2

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c19">
                                    <div className="col-1 border-right">
                                        <b>19</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Pattern: Multi-threaded (*NEW*)</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Same Tree (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Invert Binary Tree (medium)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Binary Search Tree Iterator (medium)

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c20">
                                    <div className="col-1 border-right">
                                        <b>20</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Miscellaneous</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Kth Smallest Number (hard)

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c21">
                                    <div className="col-1 border-right">
                                        <b>21</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Certificate</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-certificate text-danger"></i> &nbsp;
                                            Certificate
                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c22">
                                    <div className="col-1 border-right">
                                        <b>22</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Conclusions</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Where to Go from Here

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />

                    {/* Modal */}
                    <Modal show={show}>
                        <Modal.Body style={{background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145', color: 'white'}}>
                            <Form>
                                <Form.Control type="text" id="coursename" hidden value="Grokking the Coding Interview: Patterns for Coding Questions" />
                                <Form.Group className="mb-3" controlId="fname">
                                    <Form.Label>First Name <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control type="text" onKeyUp={(e) => fname(e)} id="fname" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="lname">
                                    <Form.Label>Last Name <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control type="text" onKeyUp={(e) => lname(e)} id="lname" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control type="email" onKeyUp={(e) => email(e)} id="email" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="phone">
                                    <Form.Label>Phone # <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control type="number" onKeyUp={(e) => phone(e)} id="phone" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="country">
                                    <Form.Label>Country <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control as="select" onChange={(e) => countryy(e)} id="country">
                                        <option value="">Select</option>
                                        {
                                            country.length > 0 ?
                                                country.map((c =>
                                                    <option>{c.countryName}</option>
                                                ))
                                                : ""
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer style={{background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145', color: 'white'}}>
                            <Button variant="secondary" onClick={handleClose}>Close</Button>
                            <Button variant="warning" className='disabled' id="sendOrder" onClick={sendOrder}>Save Changes</Button>
                        </Modal.Footer>
                    </Modal>
                </>
            )
        } else if (parameter1.parameter2 == "grokking-the-object-oriented-design-interview") {
            return (
                <>
                    <LoadingBar color='orangered' progress={100} height={5} />
                    <Helmet>
                        <title>{parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')}  {year}</title>
                        <meta property="og:title" content={parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '') + ' - ' + year} />
                        <meta name='description' content='The software engineering interview process has traditionally included a significant amount of object-oriented design questions. The majority of engineers have trouble with object-oriented design interviews (OODI), in part because they lack experience in developing complicated system designs and in part because OODI is unstructured. Even engineers who have some background in creating such systems find these interviews difficult. It is mostly because there are no predefined solutions to the design difficulties.' />
                        <meta property="og:description" content="The software engineering interview process has traditionally included a significant amount of object-oriented design questions. The majority of engineers have trouble with object-oriented design interviews (OODI), in part because they lack experience in developing complicated system designs and in part because OODI is unstructured. Even engineers who have some background in creating such systems find these interviews difficult. It is mostly because there are no predefined solutions to the design difficulties." />
                        <link rel="canonical" href={window.location.href} />
                    </Helmet>
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className='col-12'>
                                <div className='row justify-content-center' style={{ paddingTop: '5%', paddingBottom: '4%', background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>
                                    <div className="col-md-5 mb-md-0 mb-4">
                                        <LazyLoadImage effect="blur" src={course5} className="card-img-top" style={{ width: '80%' }} alt="course5" />
                                    </div>
                                    <div className="col-md-5 my-auto text-center text-white text-md-left">
                                        <h1 className="learnworlds-subheading-large">Grokking the Object Oriented Design Interview</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-md-auto mt-3">
                                <div className="row mt-3" id="icons">
                                    <div className="col-auto">
                                        <i className="fa fa-graduation-cap text-danger"></i> &nbsp; Author: Coding Courses Hub
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-line-chart text-danger"></i> &nbsp; Level: Beginner
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-clock-o text-danger"></i> &nbsp; Study time: 15h
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-lock text-danger"></i> &nbsp; Lessons: 25
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-bar-chart text-danger"></i> &nbsp; Illustrations: 108
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-bar-chart text-danger"></i> &nbsp; Code Snippets: 181
                                    </div>
                                </div>
                                <br />
                                <h3 className="learnworlds-subheading-large">Course Overview</h3>
                                <br />
                                <p>The software engineering interview process has traditionally included a significant amount of object-oriented design questions. The majority of engineers have trouble with object-oriented design interviews (OODI), in part because they lack experience in developing complicated system designs and in part because OODI is unstructured. Even engineers who have some background in creating such systems find these interviews difficult. It is mostly because there are no predefined solutions to the design difficulties.</p>
                                <br />
                                <p>
                                    This course is a comprehensive manual for learning the OODI. It was made by a group of recruiting managers from Google, Facebook, Microsoft, and Amazon called Coding Courses Hub. It offers a solid Grokking of how to deal with various object-oriented design scenarios in addition to having a collection of well-chosen case studies that have been frequently requested at the top software businesses. Although the focus of this course is not on writing code, it does contain some Java and Python code examples.
                                </p>
                            </div>
                            <div className="col-md-4 pt-4" style={{ backgroundColor: '#E9E9E9' }}>
                                <div className="row justify-content-center">
                                    <div className="col-auto">
                                        <h3>Lifetime Access</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-auto">
                                        <u><b>CERTIFICATION INCLUDED</b></u>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-auto">
                                        <p>Certification on completing the course.</p>
                                    </div>
                                </div>
                                <LazyLoadImage effect="blur" src={certificate} style={{ width: '100%' }} />
                                <br /><br />
                                <div className="row">
                                    <div className="col-auto">
                                        <u><b>UNLIMITED ACCESS</b></u>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-auto">
                                        <p>Lifetime access including all future updates.</p>
                                    </div>
                                </div>
                                <div className="row bg-white m-2 pr-3 py-4">
                                    <div className="col-12 text-center mb-2">
                                        <b>PAY ONCE</b>
                                        <br />
                                        <span>Free</span>
                                    </div>
                                    <div className="col-12">
                                        <button onClick={(e) => handleShow(e)} className="btn btn-outline-danger btn-block">Enroll</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container guruscourses'>
                        <div className='row'>
                            <div className="col-12 mt-4 border-top">
                                <br />
                                <h3 className="learnworlds-subheading-large">Course Contents</h3>
                                <br />
                                <div className="row c1">
                                    <div className="col-1 border-right">
                                        <b>01</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Object-Oriented Design and UML</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Object-Oriented Basics

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            OO Analysis and Design

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            What is UML?

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Use Case Diagrams

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Class Diagram

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Sequence diagram

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Activity Diagram

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c2">
                                    <div className="col-1 border-right">
                                        <b>02</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Object Oriented Design Case Studies (free)</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Design a Library Management System

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Design a Parking Lot

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c3">
                                    <div className="col-1 border-right">
                                        <b>03</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Object Oriented Design Case Studies</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Design Amazon - Online Shopping System

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Design Stack Overflow

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Design a Movie Ticket Booking System

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Design an ATM

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Design an Airline Management System

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Design Blackjack and a Deck of Cards

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Design a Hotel Management System

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Design a Restaurant Management system

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Design Chess

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Design an Online Stock Brokerage System

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Design a Car Rental System

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Design LinkedIn

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Design Cricinfo

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Design Facebook - a social network

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c4">
                                    <div className="col-1 border-right">
                                        <b>04</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Appendix</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Contact Us

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-certificate text-danger"></i> &nbsp;
                                            Certificate
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />

                    {/* Modal */}
                    <Modal show={show}>
                        <Modal.Body style={{background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145', color: 'white'}}>
                            <Form>
                                <Form.Control type="text" id="coursename" hidden value="Grokking the Object Oriented Design Interview" />
                                <Form.Group className="mb-3" controlId="fname">
                                    <Form.Label>First Name <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control type="text" onKeyUp={(e) => fname(e)} id="fname" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="lname">
                                    <Form.Label>Last Name <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control type="text" onKeyUp={(e) => lname(e)} id="lname" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control type="email" onKeyUp={(e) => email(e)} id="email" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="phone">
                                    <Form.Label>Phone # <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control type="number" onKeyUp={(e) => phone(e)} id="phone" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="country">
                                    <Form.Label>Country <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control as="select" onChange={(e) => countryy(e)} id="country">
                                        <option value="">Select</option>
                                        {
                                            country.length > 0 ?
                                                country.map((c =>
                                                    <option>{c.countryName}</option>
                                                ))
                                                : ""
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer style={{background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145', color: 'white'}}>
                            <Button variant="secondary" onClick={handleClose}>Close</Button>
                            <Button variant="warning" className='disabled' id="sendOrder" onClick={sendOrder}>Save Changes</Button>
                        </Modal.Footer>
                    </Modal>
                </>
            )
        } else if (parameter1.parameter2 == "grokking-the-advanced-system-design-interview") {
            return (
                <>
                    <LoadingBar color='orangered' progress={100} height={5} />
                    <Helmet>
                        <title>{parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')}  {year}</title>
                        <meta property="og:title" content={parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '') + ' - ' + year} />
                        <meta name='description' content='Interviews for software engineers now frequently include system design questions. Senior engineers believe that discussing system design is even more crucial than answering a code question. You can demonstrate your unique design abilities and how they will apply to developing complicated systems in a system design interview. It goes without saying that a strong performance in system design interviews will land you a senior position and higher salary.' />
                        <meta property="og:description" content="Interviews for software engineers now frequently include system design questions. Senior engineers believe that discussing system design is even more crucial than answering a code question. You can demonstrate your unique design abilities and how they will apply to developing complicated systems in a system design interview. It goes without saying that a strong performance in system design interviews will land you a senior position and higher salary." />
                        <link rel="canonical" href={window.location.href} />
                    </Helmet>
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className='col-12'>
                                <div className='row justify-content-center' style={{ paddingTop: '5%', paddingBottom: '4%', background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>
                                    <div className="col-md-5 mb-md-0 mb-4">
                                        <LazyLoadImage effect="blur" src={course3} className="card-img-top" style={{ width: '80%' }} alt="course3" />
                                    </div>
                                    <div className="col-md-5 my-auto text-center text-white text-md-left">
                                        <h1 className="learnworlds-subheading-large">Grokking the Advanced System Design Interview</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-md-auto mt-3">
                                <div className="row mt-3" id="icons">
                                    <div className="col-auto">
                                        <i className="fa fa-graduation-cap text-danger"></i> &nbsp; Author: Coding Courses Hub
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-line-chart text-danger"></i> &nbsp; Level: Advanced
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-clock-o text-danger"></i> &nbsp; Study time: 5h 30m
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-lock text-danger"></i> &nbsp; Lessons: 118
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-window-restore text-danger"></i> &nbsp; Quizzes: 16
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-bar-chart text-danger"></i> &nbsp; Illustrations: 109
                                    </div>
                                </div>
                                <br />
                                <h3 className="learnworlds-subheading-large">Course Overview</h3>
                                <br />
                                <p>Interviews for software engineers now frequently include system design questions. Senior engineers believe that discussing system design is even more crucial than answering a code question. You can demonstrate your unique design abilities and how they will apply to developing complicated systems in a system design interview. It goes without saying that a strong performance in system design interviews will land you a senior position and higher salary.</p>
                                <br />
                                <p>
                                    Grokking Advanced System Design gives a survey of renowned distributed systems' architectural design. The primary goal is to uncover critical design data that is essential to system design interviews. In addition, a collection of system design patterns that represent typical design issues and the solutions that many distributed systems have evolved over time is presented in this course.
                                </p>
                                <br />
                                <p>
                                    This is Coding Courses Hub' second course on system design interviews. You may get the answers to frequently asked system design interview questions in our first course, Grokking the System Design Interview.
                                </p>
                            </div>
                            <div className="col-md-4 pt-4" style={{ backgroundColor: '#E9E9E9' }}>
                                <div className="row justify-content-center">
                                    <div className="col-auto">
                                        <h3>Lifetime Access</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-auto">
                                        <u><b>CERTIFICATION INCLUDED</b></u>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-auto">
                                        <p>Certification on completing the course.</p>
                                    </div>
                                </div>
                                <LazyLoadImage effect="blur" src={certificate} style={{ width: '100%' }} />
                                <br /><br />
                                <div className="row">
                                    <div className="col-auto">
                                        <u><b>UNLIMITED ACCESS</b></u>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-auto">
                                        <p>Lifetime access including all future updates.</p>
                                    </div>
                                </div>
                                <div className="row bg-white m-2 pr-3 py-4">
                                    <div className="col-12 text-center mb-2">
                                        <b>PAY ONCE</b>
                                        <br />
                                        <span>Free</span>
                                    </div>
                                    <div className="col-12">
                                        <button onClick={(e) => handleShow(e)} className="btn btn-outline-danger btn-block">Enroll</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container guruscourses'>
                        <div className='row'>
                            <div className="col-12 mt-4 border-top">
                                <br />
                                <h3 className="learnworlds-subheading-large">Course Contents</h3>
                                <br />
                                <div className="row c1">
                                    <div className="col-1 border-right">
                                        <b>01</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Introduction</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            What Is This Course About?
                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c2">
                                    <div className="col-1 border-right">
                                        <b>02</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Dynamo: How to Design a Key-value store?</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Dynamo: Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            High-level Architecture

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Data Partitioning

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Replication

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Vector Clocks and Conflicting Data

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            The Life of Dynamo’s put() & get() Operations

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Anti-entropy Through Merkle Trees

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Gossip Protocol

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Dynamo Characteristics and Criticism

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Summary: Dynamo

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-list text-danger"></i> &nbsp;
                                            Quiz: Dynamo
                                            10 questions
                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-list text-danger"></i> &nbsp;
                                            Mock Interview: Dynamo
                                            8 questions
                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c3">
                                    <div className="col-1 border-right">
                                        <b>03</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Cassandra: How to Design a Wide-column NoSQL Database?</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Cassandra: Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            High-level Architecture

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Replication

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Cassandra Consistency Levels

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Gossiper

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Anatomy of Cassandra's Write Operation

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Anatomy of Cassandra's Read Operation

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Compaction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Tombstones

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Summary: Cassandra

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-list text-danger"></i> &nbsp;
                                            Quiz: Cassandra
                                            12 questions
                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-list text-danger"></i> &nbsp;
                                            Mock Interview: Cassandra
                                            9 questions
                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c4">
                                    <div className="col-1 border-right">
                                        <b>04</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Kafka: How to Design a Distributed Messaging System?</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Messaging Systems: Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Kafka: Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            High-level Architecture

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Kafka: Deep Dive

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Consumer Groups

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Kafka Workflow

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Role of ZooKeeper

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Controller Broker

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Kafka Delivery Semantics

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Kafka Characteristics

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Summary: Kafka

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-list text-danger"></i> &nbsp;
                                            Quiz: Kafka
                                            12 questions
                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-list text-danger"></i> &nbsp;
                                            Mock Interview: Kafka
                                            11 questions
                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c5">
                                    <div className="col-1 border-right">
                                        <b>05</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Chubby: How to Design a Distributed Locking Service</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Chubby: Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            High-level Architecture

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Design Rationale

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            How Chubby Works

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            File, Directories, and Handles

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Locks, Sequencers, and Lock-delays

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Sessions and Events

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Master Election and Chubby Events

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Caching

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Database

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Scaling Chubby

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Summary: Chubby

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-list text-danger"></i> &nbsp;
                                            Quiz: Chubby
                                            9 questions
                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-list text-danger"></i> &nbsp;
                                            Mock Interview: Chubby
                                            7 questions
                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c6">
                                    <div className="col-1 border-right">
                                        <b>06</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">GFS: How to Design a Distributed File Storage System?</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Google File System: Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            High-level Architecture

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Single Master and Large Chunk Size

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Metadata

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Master Operations

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Anatomy of a Read Operation

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Anatomy of a Write Operation

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Anatomy of an Append Operation

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            GFS Consistency Model and Snapshotting

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Fault Tolerance, High Availability, and Data Integrity

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Garbage Collection

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Criticism on GFS

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Summary: GFS

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-list text-danger"></i> &nbsp;
                                            Quiz: GFS
                                            10 questions
                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-list text-danger"></i> &nbsp;
                                            Mock Interview: GFS
                                            14 questions
                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c7">
                                    <div className="col-1 border-right">
                                        <b>07</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">HDFS: How to Design a Distributed File Storage System?</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Hadoop Distributed File System: Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            High-level Architecture

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Deep Dive

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Anatomy of a Read Operation

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Anatomy of a Write Operation

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Data Integrity & Caching

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Fault Tolerance

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            HDFS High Availability (HA)

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            HDFS Characteristics

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Summary: HDFS

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-list text-danger"></i> &nbsp;
                                            Quiz: HDFS
                                            7 questions
                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-list text-danger"></i> &nbsp;
                                            Mock Interview: HDFS
                                            9 questions
                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c8">
                                    <div className="col-1 border-right">
                                        <b>08</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">BigTable: How to Design a Wide-column Storage System?</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            BigTable: Introduction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            BigTable Data Model

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            System APIs

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Partitioning and High-level Architecture

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            SSTable

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            GFS and Chubby

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Bigtable Components

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Working with Tablets

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            The Life of BigTable's Read & Write Operations

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Fault Tolerance and Compaction

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            BigTable Refinements

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            BigTable Characteristics

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Summary: BigTable

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-list text-danger"></i> &nbsp;
                                            Quiz: BigTable
                                            10 questions
                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-list text-danger"></i> &nbsp;
                                            Mock Interview: BigTable
                                            7 questions
                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c9">
                                    <div className="col-1 border-right">
                                        <b>09</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">System Design Patterns</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Introduction: System Design Patterns

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            1. Bloom Filters

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            2. Consistent Hashing

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            3. Quorum

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            4. Leader and Follower

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            5. Write-ahead Log

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            6. Segmented Log

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            7. High-Water Mark

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            8. Lease

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            9. Heartbeat

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            10. Gossip Protocol

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            11. Phi Accrual Failure Detection

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            12. Split Brain

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            13. Fencing

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            14. Checksum

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            15. Vector Clocks

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            16. CAP Theorem

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            17. PACELC Theorem

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            18. Hinted Handoff

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            19. Read Repair

                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            20. Merkle Trees

                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c10">
                                    <div className="col-1 border-right">
                                        <b>10</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Final Assessment</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-list text-danger"></i> &nbsp;
                                            Quiz I
                                            15 questions
                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-list text-danger"></i> &nbsp;
                                            Quiz II
                                            15 questions
                                        </p>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-certificate text-danger"></i> &nbsp;
                                            Certificate
                                        </p>
                                    </div>
                                </div>
                                <br />
                                <div className="row c11">
                                    <div className="col-1 border-right">
                                        <b>11</b>
                                    </div>
                                    <div className="col-11">
                                        <h4 className="learnworlds-subheading-large">Appendix</h4>
                                        <p onClick={(e) => handleShow(e)}>
                                            <i className="fa fa-lock text-danger"></i> &nbsp;
                                            Contact Us

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />

                    {/* Modal */}
                    <Modal show={show}>
                        <Modal.Body style={{background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145', color: 'white'}}>
                            <Form>
                                <Form.Control type="text" id="coursename" hidden value="Grokking the Advanced System Design Interview" />
                                <Form.Group className="mb-3" controlId="fname">
                                    <Form.Label>First Name <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control type="text" onKeyUp={(e) => fname(e)} id="fname" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="lname">
                                    <Form.Label>Last Name <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control type="text" onKeyUp={(e) => lname(e)} id="lname" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control type="email" onKeyUp={(e) => email(e)} id="email" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="phone">
                                    <Form.Label>Phone # <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control type="number" onKeyUp={(e) => phone(e)} id="phone" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="country">
                                    <Form.Label>Country <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                    <Form.Control as="select" onChange={(e) => countryy(e)} id="country">
                                        <option value="">Select</option>
                                        {
                                            country.length > 0 ?
                                                country.map((c =>
                                                    <option>{c.countryName}</option>
                                                ))
                                                : ""
                                        }
                                    </Form.Control>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer style={{background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145', color: 'white'}}>
                            <Button variant="secondary" onClick={handleClose}>Close</Button>
                            <Button variant="warning" className='disabled' id="sendOrder" onClick={sendOrder}>Save Changes</Button>
                        </Modal.Footer>
                    </Modal>
                </>
            )
        } else {
            window.location.href = '/'
            // return (
            //     <>
            //         <Helmet>
            //             <title>404 Page Not Found  {year}</title>
            //             <link rel="canonical" href={window.location.href} />
            //         </Helmet>
            //         <br />
            //         <div className="container">
            //             <div className="row justify-content-center">
            //                 <LazyLoadImage effect="blur" src={_404Error} style={{ width: '10%' }} />
            //                 <div className="col-12 text-center">
            //                     <h1 style={{ fontSize: '50px' }}>404 Page Not Found</h1>
            //                 </div>
            //             </div>
            //         </div>
            //         <br />
            //     </>
            // )
        }
    } else if (parameter1.parameter1 == "educative") {
        let courseInfo = [];
        if (coursedetails.length > 0) {
            courseInfo = coursedetails[0].courseInfo.split('//');
        }

        const d = new Date();
        let year = d.getFullYear().toString();

        return (
            <>
                <LoadingBar color='orangered' progress={progress} height={5} />
                <Helmet>
                    {coursedetails.length > 0 ?
                        <title>{coursedetails[0].courseName.replace('&#39;', '`').replace('&#39;', '`').replace('&#34;', '"').replace('&#34;', '"').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')}</title>
                        : ""
                    }
                    <meta property="og:title" content={parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '') + ' - ' + year} />
                    <meta name='description' content={coursedetails.length > 0 ? coursedetails[0].overview : ""} />
                    <meta property='og:description' content={coursedetails.length > 0 ? coursedetails[0].overview : ""} />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                {
                    coursedetailserr != 1 ?
                        coursedetails.length == 0 ? "" :
                            <>
                                <div className="container-fluid" id='coursedetails'>
                                    <div className="row justify-content-center">
                                        <div className='col-12'>
                                            <div className='row justify-content-center' style={{ paddingTop: '5%', paddingBottom: '4%', background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>
                                                <div className="col-md-5 mb-md-0 mb-4">
                                                    {
                                                        coursedetails.length > 0 ?
                                                            <LazyLoadImage effect="blur" src={coursedetails[0].image} className="img-fluid" style={{ width: '80%' }} alt="course4" />
                                                            : ""
                                                    }
                                                </div>
                                                <div className="col-md-5 my-auto text-center text-white text-md-left">
                                                    <h1 className="learnworlds-subheading-large">
                                                        {
                                                            coursedetails.length > 0 ?
                                                                coursedetails[0].courseName.replace('&#39;', '`').replace('&#39;', '`').replace('&#34;', '"').replace('&#34;', '"').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')
                                                                : ""
                                                        }
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            coursedetails[0].overview != "" && coursedetails[0].overview != null ?
                                                <>
                                                    <div className="col-md-6 my-md-auto mt-3">
                                                        <div className="row mt-3" id="icons">
                                                            {
                                                                coursedetails.length > 0 ?
                                                                    courseInfo.map((courseInfo) =>
                                                                        <div className="col-auto">
                                                                            <i className="fa fa-graduation-cap text-danger"></i> &nbsp; {courseInfo}
                                                                        </div>
                                                                    )
                                                                    : ""
                                                            }
                                                        </div>
                                                        <br /><br />
                                                        {
                                                            coursedetails.length > 0 ?
                                                                <>
                                                                    <h3 className="learnworlds-subheading-large">Course Overview</h3>
                                                                    <br />
                                                                    <p>{coursedetails[0].overview}</p>
                                                                    <br />
                                                                </>
                                                                : ""
                                                        }
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className='p-4' style={{ backgroundColor: '#E9E9E9' }}>
                                                            <div className="row justify-content-center">
                                                                <div className="col-auto">
                                                                    <h3>Lifetime Access</h3>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-auto">
                                                                    <u><b>CERTIFICATION INCLUDED</b></u>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-auto">
                                                                    <p>Certification on completing the course.</p>
                                                                </div>
                                                            </div>
                                                            <LazyLoadImage effect="blur" src={certificate} style={{ width: '100%' }} />
                                                            <br /><br />
                                                            <div className="row">
                                                                <div className="col-auto">
                                                                    <u><b>UNLIMITED ACCESS</b></u>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-auto">
                                                                    <p>Lifetime access including all future updates.</p>
                                                                </div>
                                                            </div>
                                                            <div className="row bg-white m-2 pr-3 py-4">
                                                                <div className="col-12 text-center mb-2">
                                                                    <b>PAY ONCE</b>
                                                                    <br />
                                                                    <span>Free</span>
                                                                </div>
                                                                <div className="col-12">
                                                                    <button onClick={(e) => handleShow(e)} className="btn btn-outline-danger btn-block">Enroll</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : ""
                                        }

                                    </div>
                                    <br />
                                    <div className='container'>
                                        <div className='row'>
                                            <div className="col-12 mt-4 border-top">
                                                <br />
                                                <h3 className="learnworlds-subheading-large">Course Contents</h3>
                                                <br />
                                                {
                                                    coursedetails.length > 0 ?
                                                        coursedetails[0].heading.split('//').map((heading, k) => {
                                                            return (
                                                                <>
                                                                    <div className="row c1">
                                                                        <div className="col-1 border-right">
                                                                            <b>{k + 1}</b>
                                                                        </div>
                                                                        <div className="col-11">
                                                                            <h4 className="learnworlds-subheading-large">{heading.replace(';', '').replace(';', '')}</h4>
                                                                            {
                                                                                coursedetails[0].headingContent.split('||')[k].split('//').map((a, b) => {
                                                                                    return (
                                                                                        <div className="col-12">
                                                                                            <a href='#' onClick={(e) => handleShow(e)}>
                                                                                                <p>
                                                                                                    <i className="fa fa-lock text-danger"></i> &nbsp; &nbsp;
                                                                                                    {a.replace('&#39;', '`').replace('&#39;', '`').replace('&#34;', '"').replace('&#34;', '"')}
                                                                                                </p>
                                                                                            </a>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                </>
                                                            )
                                                        })
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row justify-content-center" style={{ boxShadow: '0 0 11px rgb(33 33 33 / 10%)' }}>
                                            <h1 className="font-weight-bolder" style={{ textShadow: '1px 1px 5px #A11B61' }}>Related Courses</h1>
                                        </div>
                                        <br />
                                        <div className="row justify-content-center">
                                            {
                                                relatedcourseserr != 1 ?
                                                    relatedcourses.length == 0 ? "" :
                                                        relatedcourses.map((t, k) => {
                                                            return (
                                                                <div className="col-md-3 mb-3" key={k}>
                                                                    {
                                                                        <NavLink onClick={() => setCount((c) => c + 1)} to={`/educative/` + t.urls} style={{ color: 'black' }}>
                                                                            <div className="card" style={{ width: '100%', backgroundColor: '#ffffff', height: '345px' }}>
                                                                                <LazyLoadImage effect="blur" src={t.images} className="card-img-top" style={{ width: '100%', margin: '0' }} alt="course" />
                                                                                <div className="card-body text-center mt-auto" style={{ height: '100%' }}>
                                                                                    <h5 className="card-title  font-weight-bold mb-0">Free</h5>
                                                                                    <p className="card-text text-center py-2">{t.courseName}</p>
                                                                                </div>
                                                                                <div className="card-footer text-center mt-auto bg-white border-0">
                                                                                    <button className="btn py-2 btn-block text-white" style={{ background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>Enroll</button>
                                                                                </div>
                                                                            </div>
                                                                        </NavLink>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                                <br /><br />
                            </>
                        :
                        <>
                            {
                                window.location.href = '/'
                            }
                            {/* <Helmet>
                                <title>404 Page Not Found  {year}</title>
                                <link rel="canonical" href={window.location.href} />
                            </Helmet>
                            <br />
                            <div className="container">
                                <div className="row justify-content-center">
                                    <LazyLoadImage effect="blur" src={_404Error} style={{ width: '10%' }} />
                                    <div className="col-12 text-center">
                                        <h1 style={{ fontSize: '50px' }}>404 Page Not Found</h1>
                                    </div>
                                </div>
                            </div>
                            <br /> */}
                        </>
                }

                {/* Modal */}
                <Modal show={show}>
                    <Modal.Body style={{background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145', color: 'white'}}>
                        <Form>
                            <Form.Control type="text" id="coursename" hidden value={coursedetails.length > 0 && coursedetails[0].courseName.replace('&#39;', '`').replace('&#39;', '`').replace('&#34;', '"').replace('&#34;', '"').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')} />
                            <Form.Group className="mb-3" controlId="fname">
                                <Form.Label>First Name <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                <Form.Control type="text" onKeyUp={(e) => fname(e)} id="fname" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="lname">
                                <Form.Label>Last Name <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                <Form.Control type="text" onKeyUp={(e) => lname(e)} id="lname" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                <Form.Control type="email" onKeyUp={(e) => email(e)} id="email" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="phone">
                                <Form.Label>Phone # <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                <Form.Control type="number" onKeyUp={(e) => phone(e)} id="phone" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="country">
                                <Form.Label>Country <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                <Form.Control as="select" onChange={(e) => countryy(e)} id="country">
                                    <option value="">Select</option>
                                    {
                                        country.length > 0 ?
                                            country.map((c =>
                                                <option>{c.countryName}</option>
                                            ))
                                            : ""
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer style={{background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145', color: 'white'}}>
                        <Button variant="secondary" onClick={handleClose}>Close</Button>
                        <Button variant="warning" className='disabled' id="sendOrder" onClick={sendOrder}>Save Changes</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    } else if (parameter1.parameter1 == "pluralsight") {
        const d = new Date();
        let year = d.getFullYear().toString();

        return (
            <>
                <LoadingBar color='orangered' progress={progress} height={5} />
                <Helmet>
                    {coursedetails.length > 0 ?
                        <title>{coursedetails[0].courseName.replace('&#39;', '`').replace('&#39;', '`').replace('&#34;', '"').replace('&#34;', '"').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')}</title>
                        : ""
                    }
                    <meta property="og:title" content={parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '').replace('plusplus', '++') + ' - PluralSight Courses ' + year} />
                    <meta name='description' content={coursedetails.length > 0 ? coursedetails[0].overview : ""} />
                    <meta property='og:description' content={coursedetails.length > 0 ? coursedetails[0].overview : ""} />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                {
                    coursedetailserr != 1 ?
                        coursedetails.length == 0 ? "" :
                            <>
                                <div className="container-fluid" id='coursedetails'>
                                    <div className="row justify-content-center">
                                        <div className='col-12'>
                                            <div className='row justify-content-center' style={{ paddingTop: '5%', paddingBottom: '4%', background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>
                                                <div className="col-md-5 mb-md-0 mb-4">
                                                    {/* <LazyLoadImage effect="blur" src={`/static/media/coursess.3f0327fe312adb458ffd.jpg`} className="card-img-top" style={{ width: '50%' }} alt="course4" /> */}
                                                    <LazyLoadImage effect="blur" src={`/static/media/coursess.6e7bd94c828f36d7658f.jpg`} className="img-fluid" style={{ width: '80%' }} alt="course4" />
                                                </div>
                                                <div className="col-md-5 my-auto text-center text-white text-md-left">
                                                    <h1 className="learnworlds-subheading-large">
                                                        {
                                                            coursedetails.length > 0 ?
                                                                coursedetails[0].courseName.replace('&#39;', '`').replace('&#39;', '`').replace('&#34;', '"').replace('&#34;', '"').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')
                                                                : ""
                                                        }
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            coursedetails[0].overview != "" && coursedetails[0].overview != null ?
                                                <>
                                                    <div className="col-md-6 my-md-auto mt-3">
                                                        {
                                                            coursedetails.length > 0 ?
                                                                <>
                                                                    <h3 className="learnworlds-subheading-large">Course Overview</h3>
                                                                    <p>{coursedetails[0].overview}</p>
                                                                    <br />
                                                                </>
                                                                : ""
                                                        }
                                                    </div>
                                                    <div className="col-md-4 pt-4" style={{ backgroundColor: '#E9E9E9' }}>
                                                        <div className="row justify-content-center">
                                                            <div className="col-auto">
                                                                <h3>Lifetime Access</h3>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <u><b>CERTIFICATION INCLUDED</b></u>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <p>Certification on completing the course.</p>
                                                            </div>
                                                        </div>
                                                        <LazyLoadImage effect="blur" src={certificate} style={{ width: '100%' }} />
                                                        <br /><br />
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <u><b>UNLIMITED ACCESS</b></u>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <p>Lifetime access including all future updates.</p>
                                                            </div>
                                                        </div>
                                                        <div className="row bg-white m-2 pr-3 py-4">
                                                            <div className="col-12 text-center mb-2">
                                                                <b>PAY ONCE</b>
                                                                <br />
                                                                <span>Free</span>
                                                            </div>
                                                            <div className="col-12">
                                                                <button onClick={(e) => handleShow(e)} className="btn btn-outline-danger btn-block">Enroll</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : ""
                                        }
                                    </div>
                                    <br />
                                    <div className='container'>
                                        <div className='row'>
                                            <div className="col-12 mt-4 border-top">
                                                <br />
                                                <h3 className="learnworlds-subheading-large">Course Contents</h3>
                                                <br />
                                                {
                                                    coursedetails.length > 0 ?
                                                        coursedetails[0].heading.split('//').map((heading, k) => {
                                                            return (
                                                                <>
                                                                    <div className="row c1">
                                                                        <div className="col-1 border-right">
                                                                            <b>{k + 1}</b>
                                                                        </div>
                                                                        <div className="col-11">
                                                                            <h4 className="learnworlds-subheading-large">{heading.replace(';', '').replace(';', '')}</h4>
                                                                            {
                                                                                coursedetails[0].headingContent.split('||')[k].split('//').map((a, b) => {
                                                                                    return (
                                                                                        <div className="col-12">
                                                                                            <a href='#' onClick={(e) => handleShow(e)}>
                                                                                                <p>
                                                                                                    <i className="fa fa-lock text-danger"></i> &nbsp; &nbsp;
                                                                                                    {a.replace('&#39;', '`').replace('&#39;', '`').replace('&#34;', '"').replace('&#34;', '"')}
                                                                                                </p>
                                                                                            </a>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                </>
                                                            )
                                                        })
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row justify-content-center" style={{ boxShadow: '0 0 11px rgb(33 33 33 / 10%)' }}>
                                            <h1 className="font-weight-bolder" style={{ textShadow: '1px 1px 5px #A11B61' }}>Related Courses</h1>
                                        </div>
                                        <br />
                                        <div className="row justify-content-center">
                                            {
                                                relatedcourseserr != 1 ?
                                                    relatedcourses.length == 0 ? "" :
                                                        relatedcourses.map((t, k) => {
                                                            return (
                                                                <div className="col-md-3 mb-3" key={k}>
                                                                    {
                                                                        <NavLink onClick={() => setCount((c) => c + 1)} to={`/pluralsight/` + t.urls} style={{ color: 'black' }}>
                                                                            <div className="card" style={{ width: '100%', backgroundColor: '#ffffff', height: '380px' }}>
                                                                                <LazyLoadImage effect="blur" src={`/static/media/coursess.6e7bd94c828f36d7658f.jpg`} className="card-img-top" style={{ width: '100%', margin: '0' }} alt="course" />
                                                                                <div className="card-body text-center mt-auto" style={{ height: '100%' }}>
                                                                                    <h5 className="card-title font-weight-bold mb-0">Free</h5>
                                                                                    <p className="card-text py-2">{t.courseName.replace('&#34;', '"').replace('&#39;', '`').replace('&#34;', '"').replace('&amp;', '&')}</p>
                                                                                </div>
                                                                                <small className='text-center mb-1'>By <b>{t.author}</b></small>
                                                                                <div className="card-footer text-center mt-auto bg-white border-0">
                                                                                    <button className="btn py-2 btn-block text-white" style={{ background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>Enroll</button>
                                                                                </div>
                                                                            </div>
                                                                        </NavLink>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                                <br /><br />
                            </>
                        :
                        <>
                            {
                                window.location.href = '/'
                            }
                            {/* <Helmet>
                                <title>404 Page Not Found  {year}</title>
                                <link rel="canonical" href={window.location.href} />
                            </Helmet>
                            <br />
                            <div className="container">
                                <div className="row justify-content-center">
                                    <LazyLoadImage effect="blur" src={_404Error} style={{ width: '10%' }} />
                                    <div className="col-12 text-center">
                                        <h1 style={{ fontSize: '50px' }}>404 Page Not Found</h1>
                                    </div>
                                </div>
                            </div>
                            <br /> */}
                        </>
                }

                {/* Modal */}
                <Modal show={show}>
                    <Modal.Body style={{background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145', color: 'white'}}>
                        <Form>
                            <Form.Control type="text" id="coursename" hidden value={coursedetails.length > 0 && coursedetails[0].courseName.replace('&#39;', '`').replace('&#39;', '`').replace('&#34;', '"').replace('&#34;', '"').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')} />
                            <Form.Group className="mb-3" controlId="fname">
                                <Form.Label>First Name <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                <Form.Control type="text" onKeyUp={(e) => fname(e)} id="fname" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="lname">
                                <Form.Label>Last Name <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                <Form.Control type="text" onKeyUp={(e) => lname(e)} id="lname" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                <Form.Control type="email" onKeyUp={(e) => email(e)} id="email" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="phone">
                                <Form.Label>Phone # <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                <Form.Control type="number" onKeyUp={(e) => phone(e)} id="phone" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="country">
                                <Form.Label>Country <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                <Form.Control as="select" onChange={(e) => countryy(e)} id="country">
                                    <option value="">Select</option>
                                    {
                                        country.length > 0 ?
                                            country.map((c =>
                                                <option>{c.countryName}</option>
                                            ))
                                            : ""
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer style={{background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145', color: 'white'}}>
                        <Button variant="secondary" onClick={handleClose}>Close</Button>
                        <Button variant="warning" className='disabled' id="sendOrder" onClick={sendOrder}>Save Changes</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    } else if (parameter1.parameter1 == "skillshare") {
        const d = new Date();
        let year = d.getFullYear().toString();

        return (
            <>
                <LoadingBar color='orangered' progress={progress} height={5} />
                <Helmet>
                    {coursedetails.length > 0 ?
                        <title>{coursedetails[0].courseName.replace('&#39;', '`').replace('&#39;', '`').replace('&#34;', '"').replace('&#34;', '"').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')}</title>
                        : ""
                    }
                    <meta property="og:title" content={parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '').replace('plusplus', '++') + ' - SkillShare Courses ' + year} />
                    <meta name='description' content={coursedetails.length > 0 ? coursedetails[0].overview : ""} />
                    <meta property='og:description' content={coursedetails.length > 0 ? coursedetails[0].overview : ""} />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                {
                    coursedetailserr != 1 ?
                        coursedetails.length == 0 ? "" :
                            <>
                                <div className="container-fluid" id='coursedetails'>
                                    <div className="row justify-content-center">
                                        <div className='col-12'>
                                            <div className='row justify-content-center' style={{ paddingTop: '5%', paddingBottom: '4%', background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>
                                                <div className="col-md-5 mb-md-0 mb-4">
                                                    {/* <LazyLoadImage effect="blur" src={courses1} className="img-fluid" style={{ width: '80%' }} alt="course4" /> */}
                                                    <LazyLoadImage effect="blur" src={`/static/media/coursee.f44c6f5971eda5aa19c7.jpeg`} className="img-fluid" style={{ width: '80%' }} alt="course4" />
                                                </div>
                                                <div className="col-md-5 my-auto text-center text-white text-md-left">
                                                    <h1 className="learnworlds-subheading-large">
                                                        {
                                                            coursedetails.length > 0 ?
                                                                coursedetails[0].courseName.replace('&#39;', '`').replace('&#39;', '`').replace('&#34;', '"').replace('&#34;', '"').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')
                                                                : ""
                                                        }
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            coursedetails[0].overview != "" && coursedetails[0].overview != null ?
                                                <>
                                                    <div className="col-md-6 my-md-auto mt-3">
                                                        {
                                                            coursedetails.length > 0 ?
                                                                <>
                                                                    <h3 className="learnworlds-subheading-large">Course Overview</h3>
                                                                    <p>{coursedetails[0].overview}</p>
                                                                    <br />
                                                                </>
                                                                : ""
                                                        }
                                                    </div>
                                                    <div className="col-md-4 pt-4" style={{ backgroundColor: '#E9E9E9' }}>
                                                        <div className="row justify-content-center">
                                                            <div className="col-auto">
                                                                <h3>Lifetime Access</h3>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <u><b>CERTIFICATION INCLUDED</b></u>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <p>Certification on completing the course.</p>
                                                            </div>
                                                        </div>
                                                        <LazyLoadImage effect="blur" src={certificate} style={{ width: '100%' }} />
                                                        <br /><br />
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <u><b>UNLIMITED ACCESS</b></u>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <p>Lifetime access including all future updates.</p>
                                                            </div>
                                                        </div>
                                                        <div className="row bg-white m-2 pr-3 py-4">
                                                            <div className="col-12 text-center mb-2">
                                                                <b>PAY ONCE</b>
                                                                <br />
                                                                <span>Free</span>
                                                            </div>
                                                            <div className="col-12">
                                                                <button onClick={(e) => handleShow(e)} className="btn btn-outline-danger btn-block">Enroll</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : ""
                                        }
                                    </div>
                                    <br />
                                    <div className='container'>
                                        <div className='row'>
                                            <div className="col-12 mt-4 border-top">
                                                <br />
                                                <h3 className="learnworlds-subheading-large">Course Contents</h3>
                                                <br />
                                                {
                                                    coursedetails.length > 0 ?
                                                        coursedetails[0].courseContent.split('//').map((content, k) => {
                                                            return (
                                                                <div className="row c1">
                                                                    <div className="col-12">
                                                                        <a href='#' onClick={(e) => handleShow(e)}>
                                                                            <p>
                                                                                <i className="fa fa-lock text-danger"></i> &nbsp; &nbsp;
                                                                                {content.replace('&#39;', '`').replace('&#39;', '`').replace('&#34;', '"').replace('&#34;', '"').replace('amp;', '')}
                                                                            </p>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        : ""
                                                }
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row justify-content-center" style={{ boxShadow: '0 0 11px rgb(33 33 33 / 10%)' }}>
                                            <h1 className="font-weight-bolder" style={{ textShadow: '1px 1px 5px #A11B61' }}>Related Courses</h1>
                                        </div>
                                        <br />
                                        <div className="row justify-content-center">
                                            {
                                                relatedcourseserr != 1 ?
                                                    relatedcourses.length == 0 ? '' :
                                                        relatedcourses.map((t, k) => {
                                                            return (
                                                                <div className="col-md-3 mb-3" key={k}>
                                                                    {
                                                                        <NavLink onClick={() => setCount((c) => c + 1)} to={`/skillshare/` + t.urls} style={{ color: 'black' }}>
                                                                            <div className="card" style={{ width: '100%', backgroundColor: '#ffffff', height: '380px' }}>
                                                                                <LazyLoadImage effect="blur" src={`/static/media/coursee.f44c6f5971eda5aa19c7.jpeg`} className="card-img-top" style={{ width: '100%', margin: '0' }} alt="course" />
                                                                                <div className="card-body text-center mt-auto" style={{ height: '100%' }}>
                                                                                    <h5 className="card-title font-weight-bold mb-0">Free</h5>
                                                                                    <p className="card-text py-2">{t.courseName.replace('&#34;', '"').replace('&#39;', '`').replace('&#34;', '"').replace('&amp;', '&')}</p>
                                                                                </div>
                                                                                <small className='text-center mb-1'>By <b>{t.author}</b></small>
                                                                                <div className="card-footer text-center mt-auto bg-white border-0">
                                                                                    <button className="btn py-2 btn-block text-white" style={{ background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>Enroll</button>
                                                                                </div>
                                                                            </div>
                                                                        </NavLink>
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                                <br /><br />
                            </>
                        :
                        <>
                            {
                                window.location.href = '/'
                            }
                            {/* <Helmet>
                                <title>404 Page Not Found  {year}</title>
                                <link rel="canonical" href={window.location.href} />
                            </Helmet>
                            <br />
                            <div className="container">
                                <div className="row justify-content-center">
                                    <LazyLoadImage effect="blur" src={_404Error} style={{ width: '10%' }} />
                                    <div className="col-12 text-center">
                                        <h1 style={{ fontSize: '50px' }}>404 Page Not Found</h1>
                                    </div>
                                </div>
                            </div>
                            <br /> */}
                        </>
                }

                {/* Modal */}
                <Modal show={show}>
                    <Modal.Body style={{background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145', color: 'white'}}>
                        <Form>
                            <Form.Control type="text" id="coursename" hidden value={coursedetails.length > 0 && coursedetails[0].courseName.replace('&#39;', '`').replace('&#39;', '`').replace('&#34;', '"').replace('&#34;', '"').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')} />
                            <Form.Group className="mb-3" controlId="fname">
                                <Form.Label>First Name <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                <Form.Control type="text" onKeyUp={(e) => fname(e)} id="fname" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="lname">
                                <Form.Label>Last Name <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                <Form.Control type="text" onKeyUp={(e) => lname(e)} id="lname" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                <Form.Control type="email" onKeyUp={(e) => email(e)} id="email" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="phone">
                                <Form.Label>Phone # <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                <Form.Control type="number" onKeyUp={(e) => phone(e)} id="phone" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="country">
                                <Form.Label>Country <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                <Form.Control as="select" onChange={(e) => countryy(e)} id="country">
                                    <option value="">Select</option>
                                    {
                                        country.length > 0 ?
                                            country.map((c =>
                                                <option>{c.countryName}</option>
                                            ))
                                            : ""
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer style={{background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145', color: 'white'}}>
                        <Button variant="secondary" onClick={handleClose}>Close</Button>
                        <Button variant="warning" className='disabled' id="sendOrder" onClick={sendOrder}>Save Changes</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    } else if (parameter1.parameter1 == "freecodecamp") {
        const d = new Date();
        let year = d.getFullYear().toString();

        return (
            <>
                <LoadingBar color='orangered' progress={progress} height={5} />
                <Helmet>
                    {coursedetails.length > 0 ?
                        <title>{coursedetails[0].courseName.replace('&#39;', '`').replace('&#39;', '`').replace('&#34;', '"').replace('&#34;', '"').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')}</title>
                        : ""
                    }
                    <meta property="og:title" content={parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '').replace('plusplus', '++') + ' - SkillShare Courses ' + year} />
                    <meta name='description' content={coursedetails.length > 0 ? coursedetails[0].overview : ""} />
                    <meta property='og:description' content={coursedetails.length > 0 ? coursedetails[0].overview : ""} />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                {
                    coursedetailserr != 1 ?
                        coursedetails.length == 0 ? "" :
                            <>
                                <div className="container-fluid" id='coursedetails'>
                                    <div className="row justify-content-center">
                                        <div className='col-12'>
                                            <div className='row justify-content-center' style={{ paddingTop: '5%', paddingBottom: '4%', background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>
                                                <div className="col-md-5 mb-md-0 mb-4">
                                                    {/* <LazyLoadImage effect="blur" src={blog4} style={{ width: '80%' }} /> */}
                                                    <LazyLoadImage effect="blur" src={courses1} className="img-fluid" style={{ width: '80%' }} alt="course4" />
                                                </div>
                                                <div className="col-md-5 my-auto text-center text-white text-md-left">
                                                    <h1 className="learnworlds-subheading-large">
                                                        {
                                                            coursedetails.length > 0 ?
                                                                coursedetails[0].courseName.replace('&#39;', '`').replace('&#39;', '`').replace('&#34;', '"').replace('&#34;', '"').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')
                                                                : ""
                                                        }
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            coursedetails[0].overview != "" && coursedetails[0].overview != null ?
                                                <>
                                                    <div className="col-md-6 my-md-auto mt-3">
                                                        {
                                                            coursedetails.length > 0 ?
                                                                <>
                                                                    <h3 className="learnworlds-subheading-large">Course Overview</h3>
                                                                    <p>{coursedetails[0].overview}</p>
                                                                    <br />
                                                                </>
                                                                : ""
                                                        }
                                                    </div>
                                                    <div className="col-md-4 pt-4" style={{ backgroundColor: '#E9E9E9' }}>
                                                        <div className="row justify-content-center">
                                                            <div className="col-auto">
                                                                <h3>Lifetime Access</h3>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <u><b>CERTIFICATION INCLUDED</b></u>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <p>Certification on completing the course.</p>
                                                            </div>
                                                        </div>
                                                        <LazyLoadImage effect="blur" src={certificate} style={{ width: '100%' }} />
                                                        <br /><br />
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <u><b>UNLIMITED ACCESS</b></u>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <p>Lifetime access including all future updates.</p>
                                                            </div>
                                                        </div>
                                                        <div className="row bg-white m-2 pr-3 py-4">
                                                            <div className="col-12 text-center mb-2">
                                                                <b>PAY ONCE</b>
                                                                <br />
                                                                <span>Free</span>
                                                            </div>
                                                            <div className="col-12">
                                                                <button onClick={(e) => handleShow(e)} className="btn btn-outline-danger btn-block">Enroll</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                : ""
                                        }
                                    </div>
                                </div>
                                <div className='container'>
                                    <div className='row'>
                                        <div className="col-12 mt-4 border-top">
                                            <br />
                                            <h3 className="learnworlds-subheading-large">Course Contents</h3>
                                            <br />
                                            {
                                                coursedetails.length > 0 ?
                                                    coursedetails[0].heading.split('//').map((heading, k) => {
                                                        return (
                                                            <>
                                                                <div className="row c1" key={k}>
                                                                    <div className="col-1 border-right">
                                                                        <b>{k + 1}</b>
                                                                    </div>
                                                                    <div className="col-11">
                                                                        <h4 className="learnworlds-subheading-large">{heading}</h4>
                                                                        {
                                                                            coursedetails[0].headingContent.split('||')[k].split('//').map((a, b) => {
                                                                                return (
                                                                                    <div className="col-12" key={b}>
                                                                                        <a href='#' onClick={(e) => handleShow(e)}>
                                                                                            <p>
                                                                                                <i className="fa fa-lock text-danger"></i> &nbsp; &nbsp;
                                                                                                {a.replace('&#39;', '`').replace('&#39;', '`').replace('&#34;', '"').replace('&#34;', '"')}
                                                                                            </p>
                                                                                        </a>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <br />
                                                            </>
                                                        )
                                                    })
                                                    : ""
                                            }
                                        </div>
                                    </div>
                                </div>
                                <br /><br />
                            </>
                        :
                        <>
                            {
                                window.location.href = '/'
                            }
                            {/* <Helmet>
                                <title>404 Page Not Found  {year}</title>
                                <link rel="canonical" href={window.location.href} />
                            </Helmet>
                            <br />
                            <div className="container">
                                <div className="row justify-content-center">
                                    <LazyLoadImage effect="blur" src={_404Error} style={{ width: '10%' }} />
                                    <div className="col-12 text-center">
                                        <h1 style={{ fontSize: '50px' }}>404 Page Not Found</h1>
                                    </div>
                                </div>
                            </div>
                            <br /> */}
                        </>
                }

                {/* Modal */}
                <Modal show={show}>
                    <Modal.Body style={{background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145', color: 'white'}}>
                        <Form>
                            <Form.Control type="text" id="coursename" hidden value={coursedetails.length > 0 && coursedetails[0].courseName.replace('&#39;', '`').replace('&#39;', '`').replace('&#34;', '"').replace('&#34;', '"').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')} />
                            <Form.Group className="mb-3" controlId="fname">
                                <Form.Label>First Name <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                <Form.Control type="text" onKeyUp={(e) => fname(e)} id="fname" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="lname">
                                <Form.Label>Last Name <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                <Form.Control type="text" onKeyUp={(e) => lname(e)} id="lname" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                <Form.Control type="email" onKeyUp={(e) => email(e)} id="email" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="phone">
                                <Form.Label>Phone # <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                <Form.Control type="number" onKeyUp={(e) => phone(e)} id="phone" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="country">
                                <Form.Label>Country <sup style={{ color: 'red' }}>*</sup></Form.Label>
                                <Form.Control as="select" onChange={(e) => countryy(e)} id="country">
                                    <option value="">Select</option>
                                    {
                                        country.length > 0 ?
                                            country.map((c =>
                                                <option>{c.countryName}</option>
                                            ))
                                            : ""
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer style={{background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145', color: 'white'}}>
                        <Button variant="secondary" onClick={handleClose}>Close</Button>
                        <Button variant="warning" className='disabled' id="sendOrder" onClick={sendOrder}>Save Changes</Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    } else {
        window.location.href = '/'
        // return (
        //     <>
        //         <Helmet>
        //             <title>404 Page Not Found  {year}</title>
        //             <link rel="canonical" href={window.location.href} />
        //         </Helmet>
        //         <br />
        //         <div className="container">
        //             <div className="row justify-content-center">
        //                 <LazyLoadImage effect="blur" src={_404Error} style={{ width: '10%' }} />
        //                 <div className="col-12 text-center">
        //                     <h1 style={{ fontSize: '50px' }}>404 Page Not Found</h1>
        //                 </div>
        //             </div>
        //         </div>
        //         <br />
        //     </>
        // )
    }
}