import React from 'react'
import { Helmet } from "react-helmet";
import { useParams, NavLink } from "react-router-dom";
import { useState, useEffect } from 'react';
import axios from "axios";
import { Base_API_URL } from '../API.js';
import './SpecificCourses.css';
import courses from '../images/courses.png';
import coursee from '../images/coursee.jpeg';
import coursess from '../images/coursess.jpg';
import LoadingBar from 'react-top-loading-bar';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function SpecificCourses() {
    //window.scrollTo(0, 0)
    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    if (lastElement != "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }

    const parameter1 = useParams();
    //console.log(parameter1.parameter2);
    var param = parameter1.parameter2.replace(/-/g, ' ').replace('#', 'hash').replace('++', 'pp').replace(/-/g, ' ');

    const [progress, setProgress] = useState(50)
    const [specificcourse, setspecificcourse] = useState([]);
    const [specificcourseerr, setspecificcourseerr] = useState(0);
    const [coursesname, setcoursesname] = useState([]);
    const [coursesnameerr, setcoursesnameerr] = useState(0);
    const [pages1, setpages1] = useState(1);
    useEffect(() => {
        if (parameter1.parameter1 == "educative") {
            axios.get(Base_API_URL+"/api/Home/GetEducativeTopics?Name=" + param + "&pageNumber=" + pages1 + "&pageSize=8")
                .then(response => {
                    if (response.data.length > 0) {
                        setspecificcourse(pre => [...pre, ...response.data])
                        setProgress(100)

                    } else {
                        setspecificcourseerr(1)
                        setProgress(100)
                    }
                })
                .catch(err => {
                    //console.log(err);
                    setspecificcourseerr(1)
                    setProgress(100)
                })
        } else if (parameter1.parameter1 == "pluralsight") {
            axios.get(Base_API_URL+"/api/Home/GetPluralsightTopics?Name=" + param + "&pageNumber=" + pages1 + "&pageSize=8")
                .then(response => {
                    if (response.data.length > 0) {
                        setspecificcourse(pre => [...pre, ...response.data])
                        setProgress(100)

                    } else {
                        setspecificcourseerr(1)
                        setProgress(100)
                    }
                })
                .catch(err => {
                    //console.log(err);
                    setspecificcourseerr(1)
                    setProgress(100)
                })
        } else if (parameter1.parameter1 == "skillshare") {
            axios.get(Base_API_URL+"/api/Home/GetSkillShareTopics?Name=" + param + "&pageNumber=" + pages1 + "&pageSize=8")
                .then(response => {
                    if (response.data.length > 0) {
                        setspecificcourse(pre => [...pre, ...response.data])
                        setProgress(100)

                    } else {
                        setspecificcourseerr(1)
                        setProgress(100)
                    }
                })
                .catch(err => {
                    //console.log(err);
                    setspecificcourseerr(1)
                    setProgress(100)
                })
        } else {
            window.location.href = '/'
        }
    }, [pages1]);

    const [pages2, setpages2] = useState(1);
    useEffect(() => {
        if (parameter1.parameter1 == "educative") {
            axios.get(Base_API_URL+"/api/Home/GetEducativeCoursesName?pageNumber=" + pages2 + "&pageSize=20")
                .then(response => {
                    if (response.data.length > 0) {
                        setcoursesname(pre => [...pre, ...response.data])
                        setProgress(100)

                    } else {
                        setcoursesnameerr(1)
                        setProgress(100)
                    }
                })
                .catch(err => {
                    //console.log(err);
                    setcoursesnameerr(1)
                    setProgress(100)
                })
        } else if (parameter1.parameter1 == "pluralsight") {
            axios.get(Base_API_URL+"/api/Home/GetPluralsightCoursesName?pageNumber=" + pages2 + "&pageSize=20")
                .then(response => {
                    if (response.data.length > 0) {
                        setcoursesname(pre => [...pre, ...response.data])
                        setProgress(100)

                    } else {
                        setcoursesnameerr(1)
                        setProgress(100)
                    }
                })
                .catch(err => {
                    //console.log(err);
                    setcoursesnameerr(1)
                    setProgress(100)
                })
        } else if (parameter1.parameter1 == "skillshare") {
            axios.get(Base_API_URL+"/api/Home/GetSkillShareCoursesName?pageNumber=" + pages2 + "&pageSize=20")
                .then(response => {
                    if (response.data.length > 0) {
                        setcoursesname(pre => [...pre, ...response.data])
                        setProgress(100)

                    } else {
                        setcoursesnameerr(1)
                        setProgress(100)
                    }
                })
                .catch(err => {
                    //console.log(err);
                    setcoursesnameerr(1)
                    setProgress(100)
                })
        } else {
            window.location.href = '/'
        }
    }, [pages2]);
    //console.log(specificcourse);
    //console.log(coursesname);

    if (parameter1.parameter1 == "educative") {
        const load = (event) => {
            setpages1(pages1 + 1)
        }

        const btnmore = (event) => {
            setpages2(pages2 + 1)
        }

        return (
            <>
                <LoadingBar color='orangered' progress={progress} height={5} />
                <Helmet>
                    <title>Educative {parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '').replace(' Sharp', '#').replace('pp', '++')} Courses From Scratch</title>
                    <meta name="description" content={parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '').replace(' Sharp', '#').replace('pp', '++') + ` Courses`} />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <div className="container">
                    <div className="row justify-content-center" style={{ boxShadow: '0 0 11px rgb(33 33 33 / 10%)' }}>
                        <h1 className="font-weight-bolder" style={{ textShadow: '1px 1px 5px #A11B61' }}>{parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '').replace(' Sharp', '#').replace('pp', '++')} Courses</h1>
                    </div>
                    <br />
                    <div className="row justify-content-center" id="specific">
                        {
                            specificcourseerr != 1 ?
                                specificcourse.length > 0 &&
                                    specificcourse.map((t, k) => {
                                        return (
                                            <div className="col-md-3" key={k}>
                                                {
                                                    <NavLink to={`/educative/` + t.urls} style={{ color: 'black' }}>
                                                        <div className="card" style={{ width: '100%', backgroundColor: '#ffffff', height: '345px' }}>
                                                            <LazyLoadImage effect="blur" src={t.images} className="card-img-top" style={{ width: '100%', margin: '0' }} alt="course" />
                                                            <div className="card-body text-center mt-auto" style={{ height: '100%' }}>
                                                                <h5 className="card-title font-weight-bold mb-0">Free</h5>
                                                                <p className="card-text py-2">{t.courseName}</p>
                                                            </div>
                                                            <div className="card-footer text-center mt-auto bg-white border-0">
                                                                <button className="btn py-2 btn-block text-white" style={{ background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>Enroll</button>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                }
                                            </div>
                                        )
                                    })
                                : ""
                        }
                    </div>
                    {
                        specificcourse.length > 7 && specificcourseerr == 0 &&
                            <div className='container'>
                                <div className="row justify-content-center">
                                    <button onClick={(e) => load(e)} id="load" className="btn text-white btn-danger btn-block" style={{ background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>Load More</button>
                                </div>
                            </div>
                    }
                    <br /><br />
                    <div className="row justify-content-center" style={{ boxShadow: '0 0 11px rgb(33 33 33 / 10%)' }}>
                        <h1 className="font-weight-bolder" style={{ textShadow: '1px 1px 5px #A11B61' }}>Educative Courses</h1>
                    </div>
                    <br />
                    <div className="row justify-content-center" id="courses">
                        {
                            coursesname.length > 0 &&
                                coursesname.map((t, k) => {
                                    return (
                                        <div className="col-md-3" key={k}>
                                            {
                                                <NavLink to={`/educative/` + t.urls} style={{ color: 'black' }}>
                                                    <div className="card" style={{ width: '100%', backgroundColor: '#ffffff', height: '345px' }}>
                                                        <LazyLoadImage effect="blur" src={t.images} className="card-img-top" style={{ width: '100%', margin: '0' }} alt="course" />
                                                        <div className="card-body text-center mt-auto" style={{ height: '100%' }}>
                                                            <h5 className="card-title font-weight-bold mb-0">Free</h5>
                                                            <p className="card-text py-2">{t.courseName}</p>
                                                        </div>
                                                        <div className="card-footer text-center mt-auto bg-white border-0">
                                                            <button className="btn py-2 btn-block text-white" style={{ background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>Enroll</button>
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            }
                                        </div>
                                    )
                                })
                        }
                    </div>
                    {
                        coursesname.length > 0 && coursesnameerr == 0 &&
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <button onClick={(e) => btnmore(e)} id="btnmore" style={{ backgroundColor: '#9F1C62' }} className="btn btn-danger btn-block">Load More</button>
                                </div>
                            </div>
                    }
                </div>
                <br /><br />
            </>
        )
    } else if (parameter1.parameter1 == "pluralsight") {
        const load = (event) => {
            setpages1(pages1 + 1)
        }

        const btnmore = (event) => {
            setpages2(pages2 + 1)
        }

        return (
            <>
                <LoadingBar color='orangered' progress={progress} height={5} />
                <Helmet>
                    <title>PluralSight {parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '').replace(' Sharp', '#').replace('pp', '++')} Courses From Scratch</title>
                    <meta name="description" content={parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '').replace(' Sharp', '#').replace('pp', '++') + ` Courses`} />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <div className="container">
                    <div className="row justify-content-center" style={{ boxShadow: '0 0 11px rgb(33 33 33 / 10%)' }}>
                        <h1 className="font-weight-bolder" style={{ textShadow: '1px 1px 5px #A11B61' }}>{parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '').replace(' Sharp', '#').replace('pp', '++')} Courses</h1>
                    </div>
                    <br />
                    <div className="row justify-content-center" id="specific">
                        {
                            specificcourseerr != 1 ?
                                specificcourse.length > 0 &&
                                    specificcourse.map((t, k) => {
                                        return (
                                            <div className="col-md-3" key={k}>
                                                {
                                                    <NavLink to={`/pluralsight/` + t.urls} style={{ color: 'black' }}>
                                                        <div className="card" style={{ width: '100%', backgroundColor: '#ffffff', height: '355px' }}>
                                                            <LazyLoadImage effect="blur" src={courses} className="card-img-top" style={{ width: '100%', margin: '0' }} alt="course" />
                                                            <div className="card-body text-center mt-auto" style={{ height: '100%' }}>
                                                                <h5 className="card-title font-weight-bold mb-0">Free</h5>
                                                                <p className="card-text py-2">{t.courseName.replace('&#34;', '"').replace('&#39;', '`').replace('&#34;', '"').replace('&amp;', '&')}</p>
                                                            </div>
                                                            <small className='text-center mb-1'>By <b>{t.author}</b></small>
                                                            <div className="card-footer text-center mt-auto bg-white border-0">
                                                                <button className="btn py-2 btn-block text-white" style={{ background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>Enroll</button>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                }
                                            </div>
                                        )
                                    })
                                : ""
                        }
                    </div>
                    {
                        specificcourse.length > 7 && specificcourseerr == 0 &&
                            <div className='container'>
                                <div className="row justify-content-center">
                                    <button onClick={(e) => load(e)} id="load" className="btn text-white btn-danger btn-block" style={{ background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>Load More</button>
                                </div>
                            </div>
                    }
                    <br /><br />
                    <div className="row justify-content-center" style={{ boxShadow: '0 0 11px rgb(33 33 33 / 10%)' }}>
                        <h1 className="font-weight-bolder" style={{ textShadow: '1px 1px 5px #A11B61' }}>PluralSight Courses</h1>
                    </div>
                    <br />
                    <div className="row justify-content-center" id="courses">
                        {
                            coursesname.length > 0 &&
                                coursesname.map((t, k) => {
                                    return (
                                        <div className="col-md-3" key={k}>
                                            {
                                                <NavLink to={`/pluralsight/` + t.urls} style={{ color: 'black' }}>
                                                    <div className="card" style={{ width: '100%', backgroundColor: '#ffffff', height: '355px' }}>
                                                        <LazyLoadImage effect="blur" src={coursess} className="card-img-top" style={{ width: '100%', margin: '0' }} alt="course" />
                                                        <div className="card-body text-center mt-auto" style={{ height: '100%' }}>
                                                            <h5 className="card-title font-weight-bold mb-0">Free</h5>
                                                            <p className="card-text py-2">{t.courseName.replace('&#34;', '"').replace('&#39;', '`').replace('&#34;', '"').replace('&amp;', '&')}</p>
                                                        </div>
                                                        <small className='text-center mb-1'>By <b>{t.author}</b></small>
                                                        <div className="card-footer text-center mt-auto bg-white border-0">
                                                            <button className="btn py-2 btn-block text-white" style={{ background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>Enroll</button>
                                                        </div>
                                                    </div>
                                                </NavLink>

                                            }
                                        </div>
                                    )
                                })
                        }
                    </div>
                    {
                        coursesname.length > 0 && coursesnameerr == 0 &&
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <button onClick={(e) => btnmore(e)} id="btnmore" style={{ backgroundColor: '#9F1C62' }} className="btn btn-danger btn-block">Load More</button>
                                </div>
                            </div>
                    }
                </div>
                <br /><br />
            </>
        )
    } else if (parameter1.parameter1 == "skillshare") {
        const load = (event) => {
            setpages1(pages1 + 1)
        }

        const btnmore = (event) => {
            setpages2(pages2 + 1)
        }

        return (
            <>
                <LoadingBar color='orangered' progress={progress} height={5} />
                <Helmet>
                    <title>SkillShare {parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '').replace(' Sharp', '#').replace('pp', '++')} Courses From Scratch</title>
                    <meta name="description" content={parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '').replace(' Sharp', '#').replace('pp', '++') + ` Courses`} />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <div className="container">
                    <div className="row justify-content-center" style={{ boxShadow: '0 0 11px rgb(33 33 33 / 10%)' }}>
                        <h1 className="font-weight-bolder" style={{ textShadow: '1px 1px 5px #A11B61' }}>{parameter1.parameter2.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '').replace(' Sharp', '#').replace('pp', '++')} Courses</h1>
                    </div>
                    <br />
                    <div className="row justify-content-center" id="specific">
                        {
                            specificcourseerr != 1 ?
                                specificcourse.length > 0 &&
                                    specificcourse.map((t, k) => {
                                        return (
                                            <div className="col-md-3" key={k}>
                                                {
                                                    <NavLink to={`/skillshare/` + t.urls} style={{ color: 'black' }}>
                                                        <div className="card" style={{ width: '100%', backgroundColor: '#ffffff', height: '380px' }}>
                                                            <LazyLoadImage effect="blur" src={courses} className="card-img-top" style={{ width: '100%', margin: '0' }} alt="course" />
                                                            <div className="card-body text-center mt-auto" style={{ height: '100%' }}>
                                                                <h5 className="card-title font-weight-bold mb-0">Free</h5>
                                                                <p className="card-text py-2">{t.courseName.replace('&#34;', '"').replace('&#39;', '`').replace('&#34;', '"').replace('&amp;', '&')}</p>
                                                            </div>
                                                            <small className='text-center mb-1'>By <b>{t.author}</b></small>
                                                            <div className="card-footer text-center mt-auto bg-white border-0">
                                                                <button className="btn py-2 btn-block text-white" style={{ background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>Enroll</button>
                                                            </div>
                                                        </div>
                                                    </NavLink>
                                                }
                                            </div>
                                        )
                                    })
                                : ""
                        }
                    </div>
                    {
                        specificcourse.length > 7 && specificcourseerr == 0 &&
                            <div className='container'>
                                <div className="row justify-content-center">
                                    <button onClick={(e) => load(e)} id="load" className="btn text-white btn-danger btn-block" style={{ background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>Load More</button>
                                </div>
                            </div>
                    }
                    <br /><br />
                    <div className="row justify-content-center" style={{ boxShadow: '0 0 11px rgb(33 33 33 / 10%)' }}>
                        <h1 className="font-weight-bolder" style={{ textShadow: '1px 1px 5px #A11B61' }}>SkillShare Courses</h1>
                    </div>
                    <br />
                    <div className="row justify-content-center" id="courses">
                        {
                            coursesname.length > 0 &&
                                coursesname.map((t, k) => {
                                    return (
                                        <div className="col-md-3" key={k}>
                                            {
                                                <NavLink to={`/skillshare/` + t.urls} style={{ color: 'black' }}>
                                                    <div className="card" style={{ width: '100%', backgroundColor: '#ffffff', height: '380px' }}>
                                                        <LazyLoadImage effect="blur" src={coursee} className="card-img-top" style={{ width: '100%', margin: '0' }} alt="course" />
                                                        <div className="card-body text-center mt-auto" style={{ height: '100%' }}>
                                                            <h5 className="card-title font-weight-bold mb-0">Free</h5>
                                                            <p className="card-text py-2">{t.courseName.replace('&#34;', '"').replace('&#39;', '`').replace('&#34;', '"').replace('&amp;', '&')}</p>
                                                        </div>
                                                        <small className='text-center mb-1'>By <b>{t.author}</b></small>
                                                        <div className="card-footer text-center mt-auto bg-white border-0">
                                                            <button className="btn py-2 btn-block text-white" style={{ background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>Enroll</button>
                                                        </div>
                                                    </div>
                                                </NavLink>

                                            }
                                        </div>
                                    )
                                })
                        }
                    </div>
                    {
                        coursesname.length > 0 && coursesnameerr == 0 &&
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <button onClick={(e) => btnmore(e)} id="btnmore" style={{ backgroundColor: '#9F1C62' }} className="btn btn-danger btn-block">Load More</button>
                                </div>
                            </div>
                    }
                </div>
                <br /><br />
            </>
        )
    }
}