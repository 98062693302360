import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Components/Header';
import Index from './Components/Index';
import Courses from './Components/Courses';
import SpecificCourses from './Components/SpecificCourses';
import CourseDetails from './Components/CourseDetails';
import BundleDetails from './Components/BundleDetails';
import Blogs from './Components/Blogs';
import BlogDetails from './Components/BlogDetails';
import Coupons from './Components/Coupons';
import BrandCoupons from './Components/BrandCoupons';
import Footer from './Components/Footer';

function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Index />} />
          <Route path='*' element={<Index />} />
          <Route path="/:parameter/" element={<Courses />} />
          <Route path="/:parameter1/topics/:parameter2/" element={<SpecificCourses />} />
          <Route path="/:parameter1/:parameter2" element={<CourseDetails />} />
          <Route path="/bundle/:parameter" element={<BundleDetails />} />
          <Route path="/blogs/" element={<Blogs />} />
          <Route path="/blogs/:parameter" element={<BlogDetails />} />
          <Route path="/coupons/" element={<Coupons />} />
          <Route path="/coupons/:parameter" element={<BrandCoupons />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
