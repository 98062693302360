import React from 'react'
import { Helmet } from "react-helmet";
import { NavLink } from 'react-router-dom';
import './Blogs.css';
import blog1 from '../images/blog1.png';
import blog2 from '../images/blog2.png';
import blog3 from '../images/blog3.png';
import blog4 from '../images/faang.png';
import blog5 from '../images/systemdesign.png';
import blog6 from '../images/googleint.png';
import blog7 from '../images/blog7.png';
import img from '../images/blogging.png';
import LoadingBar from 'react-top-loading-bar'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Blogs() {
    window.scrollTo(0, 0)
    const d = new Date();
    let year = d.getFullYear().toString();

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    if (lastElement != "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }

    return (
        <>
            <LoadingBar color='orangered' progress={100} height={5} />
            <Helmet>
                <title>Learn, Upskill & Career Development Guide & Resources - Great Learning Blog</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <div className="container-fluid">
                <div className='row justify-content-center'>
                    <div className='col-12'>
                        <div className='row justify-content-center' style={{ paddingTop: '4%', paddingBottom: '3%', background: 'radial-gradient(39.56% 48.29% at 20% 115.78%, #FF1675 0%, rgba(255, 22, 121, 0) 100%),radial-gradient(54.23% 74.52% at 69.72% -10.08%, #FF1675 0%, rgba(255, 22, 121, 0) 100%), radial-gradient(21.67% 31.7% at 39.72% 107.79%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%), radial-gradient(40.08% 51.33% at 85.83% 24.14%, rgba(74, 51, 209, 0.8) 0%, rgba(74, 51, 209, 0) 100%) , #242145' }}>
                            <div className="col-md-5 mb-md-0 mb-4">
                                <LazyLoadImage effect="blur" src={img} className="card-img-top" style={{ width: '80%' }} alt="course3" />
                            </div>
                            <div className="col-md-5 my-auto text-center text-white text-md-left">
                                <h1 className="learnworlds-subheading-large">Join Coding Courses Hub Blogs</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <br />
                <br />
                <div className="row justify-content-center" style={{ boxShadow: '0 0 11px rgb(33 33 33 / 10%)' }}>
                    <h2 className="font-weight-bolder" style={{ textShadow: '1px 1px 5px #A11B61' }}>Latest Blogs</h2>
                </div>
                <br />
                <div className="row justify-content-center" id="blogs">
                    <div className="col-md-4">
                        <NavLink to='/blogs/prepare-faang-interviews'>
                            <div className="card" style={{ width: '100%', height: '365px', backgroundColor: '#EDEDED' }}>
                                <LazyLoadImage effect="blur" src={blog4} className="card-img-top" style={{ width: '100%', height: 'auto', margin: '0' }} alt="blog1" />
                                <div className="card-footer p-4">
                                    <h6 className="card-title font-weight-bold py-1">15 March, 2023</h6>
                                    <p className="card-text">How to Prepare for FAANG Interviews?</p>
                                    <p style={{ fontSize: '60%' }}>FAANG (Facebook, Amazon, Apple, Netflix, Google) are among the most...</p>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-md-4">
                        <NavLink to='/blogs/system-design-interview-questions'>
                            <div className="card" style={{ width: '100%', height: '365px', backgroundColor: '#EDEDED' }}>
                                <LazyLoadImage effect="blur" src={blog5} className="card-img-top" style={{ width: '100%', height: 'auto', margin: '0' }} alt="blog1" />
                                <div className="card-footer p-4">
                                    <h6 className="card-title font-weight-bold py-1">15 March, 2023</h6>
                                    <p className="card-text">Top 10 System design interview questions and Answers</p>
                                    <p style={{ fontSize: '60%' }}>System design interviews assess a candidate's ability to design....</p>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-md-4">
                        <NavLink to='/blogs/behavioral-interview-questions'>
                            <div className="card" style={{ width: '100%', height: '365px', backgroundColor: '#EDEDED' }}>
                                <LazyLoadImage effect="blur" src={blog7} className="card-img-top" style={{ width: '100%', height: 'auto', margin: '0' }} alt="blog1" />
                                <div className="card-footer p-4">
                                    <h6 className="card-title font-weight-bold py-1">15 March, 2023</h6>
                                    <p className="card-text">Behavioral Interview Questions & Answers for FAANG Software Engineer</p>
                                    <p style={{ fontSize: '60%' }}>FAANG companies tend to ask a lot of behavioral interview questions...</p>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-md-4">
                        <NavLink to='/blogs/google-interview-questions'>
                            <div className="card" style={{ width: '100%', height: '365px', backgroundColor: '#EDEDED' }}>
                                <LazyLoadImage effect="blur" src={blog6} className="card-img-top" style={{ width: '100%', height: 'auto', margin: '0' }} alt="blog1" />
                                <div className="card-footer p-4">
                                    <h6 className="card-title font-weight-bold py-1">15 March, 2023</h6>
                                    <p className="card-text">Top 10 Google Interview Question and Answers in 2023</p>
                                    <p style={{ fontSize: '60%' }}>Google is known for asking challenging and thought-provoking interview...</p>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-md-4">
                        <NavLink to='/blogs/complete-guide-sys-design'>
                            <div className="card" style={{ width: '100%', height: '365px', backgroundColor: '#EDEDED' }}>
                                <LazyLoadImage effect="blur" src={blog1} className="card-img-top" style={{ width: '100%', height: 'auto', margin: '0' }} alt="blog1" />
                                <div className="card-footer p-4">
                                    <h6 className="card-title font-weight-bold py-1">10 AUGUST, 2022</h6>
                                    <p className="card-text">The Complete Guide to Ace the System Design Interview</p>
                                    <p style={{ fontSize: '60%' }}>The 7–step process to answer any system design interview question.</p>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-md-4">
                        <NavLink to='/blogs/leetcode-patterns-for-faang-interviews'>
                            <div className="card" style={{ width: '100%', height: '365px', backgroundColor: '#EDEDED' }}>
                                <LazyLoadImage effect="blur" src={blog2} className="card-img-top" style={{ width: '100%', height: 'auto', margin: '0' }} alt="blog2" />
                                <div className="card-footer p-4">
                                    <h6 className="card-title font-weight-bold py-1">07 AUGUST, 2022</h6>
                                    <p className="card-text">Top LeetCode Patterns for FAANG Coding Interviews</p>
                                    <p style={{ fontSize: '60%' }}>Coding patterns enhance our “ability to map a new problem to an already known problem.</p>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-md-4">
                        <NavLink to='/blogs/sys-design-distinguishes'>
                            <div className="card" style={{ width: '100%', height: '365px', backgroundColor: '#EDEDED' }}>
                                <LazyLoadImage effect="blur" src={blog3} className="card-img-top" style={{ width: '100%', height: 'auto', margin: '0' }} alt="blog3" />
                                <div className="card-footer p-4">
                                    <h6 className="card-title font-weight-bold py-1">31 JULY, 2022</h6>
                                    <p className="card-text">System Design Interviews: What distinguishes you from others?</p>
                                    <p style={{ fontSize: '60%' }}>Stand out from the crowd with these system design interview tips.</p>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>
            <br />
        </>
    )
}