import React from 'react'
import { NavLink } from 'react-router-dom';
import logo from '../images/Capture1.PNG';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Header() {
    function openNav() {
        document.getElementById("links").style.width = "70%";
    }

    const closeNav = (e) => {
        if (document.getElementById("navbarScroll") != null) {
            document.getElementById("navbarScroll").classList.remove("show");
        }
    }

    return (
        <header>
            {/* Pc or Laptop screens Navbar */}
            <Navbar expand="lg" variant='dark' className="bg-body-tertiary d-md-block d-none" style={{ backgroundColor: '#1b1834' }}>
                <Container fluid>
                    <NavLink rel="home" to={`/`}>
                        <span style={{ fontFamily: 'Copperplate', fontSize: '20px' }} className='text-white font-weight-bold'> Coding <span className='text-warning'>Courses</span> Hub</span>
                    </NavLink>
                    <Nav className="mx-auto" id='links1'>
                        <li className="nav-item active" style={{ backgroundColor: '' }}>
                            <NavLink className="nav-link" to={`/`}><i className="fa fa-home"></i> Home</NavLink>
                        </li>
                        <li className="nav-item dropdown active" style={{ backgroundColor: '' }}>
                            <a className="nav-link dropdown-toggle" role="button" data-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-book"></i> Courses
                            </a>
                            <div className="dropdown-menu">
                                <NavLink className="dropdown-item font-weight-normal" to="/designgurus/">DesignGurus Courses</NavLink>
                                <NavLink className="dropdown-item" to="/educative/">Educative Courses</NavLink>
                                <NavLink className="dropdown-item" to="/pluralsight/">PluralSight Courses</NavLink>
                                <NavLink className="dropdown-item" to="/skillshare/">SkillShare Courses</NavLink>
                                {/* <NavLink className="dropdown-item" to="/freecodecamp/">FreeCodeCamp Courses</NavLink> */}
                            </div>
                        </li>
                        <li className="nav-item active" style={{ backgroundColor: '' }}>
                            <NavLink className="nav-link" to={`/blogs/`}><i className="fa fa-newspaper-o"></i> Blogs</NavLink>
                        </li>
                        <li className="nav-item active" style={{ backgroundColor: '' }}>
                            <NavLink className="nav-link" to={`/coupons/`}><i className="fa fa-sticky-note"></i> Coupons</NavLink>
                        </li>
                        <li className="nav-item active" style={{ backgroundColor: '' }}>
                            <NavLink className="nav-link" to={`/write-for-us/`}><i className="fa fa-edit"></i> Write for Us</NavLink>
                        </li>
                    </Nav>
                    {/* <form className="form-inline my-2 my-lg-0 ml-auto d-md-block d-none">
                        <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" />
                        <button className="btn btn-warning my-2 my-sm-0" type="submit">Search</button>
                    </form> */}
                    <Form className="d-flex">
                        <Form.Control type="search" placeholder="Search..." className="mr-2" aria-label="Search" />
                        <Button variant="warning">Search</Button>
                    </Form>
                </Container>
            </Navbar>

            {/* Mobile screens Navbar */}
            <Navbar expand="lg" className="bg-body-tertiary d-md-none d-block" style={{ backgroundColor: '#1b1834' }}>
                <Container fluid>
                    <NavLink rel="home" to={`/`} style={{ width: '270px' }}>
                        <span style={{ fontFamily: 'Copperplate', fontSize: '20px' }} className='text-white font-weight-bold'> Coding <span className='text-warning'>Courses</span> Hub</span>
                    </NavLink>
                    <Navbar.Toggle aria-controls="navbarScroll" className="bg-white" />
                    <Navbar.Collapse id="navbarScroll">
                        <Nav className="mx-auto font-weight-bold" id="links2">
                            <li className="nav-item active bg-white border-0 d-lg-none d-block text-center">
                                <LazyLoadImage effect="blur" src={logo} style={{ width: "70%" }} />
                                <Button className="close" data-dismiss="navbar-collapse" onClick={() => closeNav()} aria-expanded="false" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </Button>
                            </li>
                            <li className="nav-item activ" style={{ backgroundColor: '' }}>
                                <NavLink className="nav-link text-white" onClick={() => closeNav()} to={`/`}><i className="fa fa-home"></i> Home</NavLink>
                            </li>
                            <li className="nav-item dropdown active" style={{ backgroundColor: '' }}>
                                <a className="nav-link dropdown-toggle text-white" role="button" data-toggle="dropdown" aria-expanded="false">
                                    <i className="fa fa-book"></i> Courses
                                </a>
                                <div className="dropdown-menu">
                                    <NavLink className="dropdown-item" onClick={() => closeNav()} to="/designgurus/">DesignGurus Courses</NavLink>
                                    <NavLink className="dropdown-item" onClick={() => closeNav()} to="/educative/">Educative Courses</NavLink>
                                    <NavLink className="dropdown-item" onClick={() => closeNav()} to="/pluralsight/">PluralSight Courses</NavLink>
                                    <NavLink className="dropdown-item" onClick={() => closeNav()} to="/skillshare/">SkillShare Courses</NavLink>
                                    {/* <NavLink className="dropdown-item" onClick={() => closeNav()} to="/freecodecamp/">FreeCodeCamp Courses</NavLink> */}
                                </div>
                            </li>
                            <li className="nav-item active" style={{ backgroundColor: '' }}>
                                <NavLink className="nav-link text-white" onClick={() => closeNav()} to="/blogs/"><i className="fa fa-newspaper-o"></i> Blogs</NavLink>
                            </li>
                            <li className="nav-item active" style={{ backgroundColor: '' }}>
                                <NavLink className="nav-link text-white" onClick={() => closeNav()} to="/coupons/"><i className="fa fa-sticky-note"></i> Coupons</NavLink>
                            </li>
                            <li className="nav-item active" style={{ backgroundColor: '' }}>
                                <NavLink className="nav-link text-white" onClick={() => closeNav()} to="/write-for-us/"><i className="fa fa-edit"></i> Write for Us</NavLink>
                            </li>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}