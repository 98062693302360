import React from 'react'
import { Helmet } from "react-helmet";
import { NavLink, useParams } from "react-router-dom";
import { useState, useEffect } from 'react';
import './BundleDetails.css';
import course1 from '../images/course1.png';
import course2 from '../images/course2.png';
import course3 from '../images/course3.png';
import course4 from '../images/course4.png';
import course5 from '../images/course5.png';
import bundle1 from '../images/bundle1.jpeg';
import bundle2 from '../images/bundle2.png';
import bundle3 from '../images/bundle3.png';
import bundle4 from '../images/bundle4.png';
import bundle5 from '../images/bundle5.png';
import _404Error from '../images/_404Error.png';
import LoadingBar from 'react-top-loading-bar';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function BundleDetails() {
    window.scrollTo(0, 0)
    const parameter = useParams();
    //console.log(parameter.parameter);

    const d = new Date();
    let year = d.getFullYear().toString();

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    if (lastElement != "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }

    if (parameter.parameter == "all-courses-by-design-gurus") {
        return (
            <>
                <LoadingBar color='orangered' progress={100} height={5} />
                <Helmet>
                    <title>{parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')}  {year}</title>
                    <meta name="description" content="Coding Courses Hub is an internet based stage that has all that computer programmers need to get ready for their coding and framework configuration interviews." />
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mb-md-0 mb-5">
                            <p>OUR GREAT OFFER</p>
                            <h1 className="learnworlds-subheading-large">Tech Interview Bundle</h1>
                            <br />
                            <p>Includes everything you need to prepare for technical interviews. Save 30% when you purchase the four courses together and have lifetime access.</p>
                            <br />
                            <div className="row justify-content-around">
                                <div className="col-3 border text-center" style={{ backgroundColor: 'whitesmoke' }}>
                                    <span>4</span>
                                    <p>Courses</p>
                                </div>
                                <div className="col-3 border text-center" style={{ backgroundColor: 'whitesmoke' }}>
                                    <span>30%</span>
                                    <p>Discount</p>
                                </div>
                                <div className="col-3 border text-center" style={{ backgroundColor: 'whitesmoke' }}>
                                    <span>$87</span>
                                    <p>You Save</p>
                                </div>
                            </div>
                            <br />
                            <br />
                            <button className="btn btn-danger">Buy now <del>$286</del> $199</button>
                            <br />
                            <br />
                            <br />
                            <h3 className="learnworlds-subheading-large">Tech Interview Bundle</h3>
                            <br />
                            <button className="btn btn-danger">Buy now <del>$286</del> $199</button>
                        </div>
                        <div className="col-md-4 mt-auto">
                            <LazyLoadImage effect="blur" src={bundle1} className="card-img-top" style={{ width: '100%', height: '174px' }} alt="bundle1" />
                            <br />
                            <br />
                            <NavLink to="/designgurus/grokking-the-advanced-system-design-interview">
                                <div className="row">
                                    <div className="col-5">
                                        <LazyLoadImage effect="blur" src={course3} className="card-img-top" style={{ width: '100%' }} alt="course3" />
                                    </div>
                                    <div className="col-6 p-0">
                                        <p>Grokking the Advanced System Design Interview</p>
                                    </div>
                                    <div className="col-1 p-0">
                                        <p>Free</p>
                                    </div>
                                </div>
                            </NavLink>
                            <br />
                            <NavLink to="/designgurus/grokking-the-coding-interview-patterns-for-coding-questions">
                                <div className="row">
                                    <div className="col-5">
                                        <LazyLoadImage effect="blur" src={course1} className="card-img-top" style={{ width: '100%' }} alt="course1" />
                                    </div>
                                    <div className="col-6 p-0">
                                        <p>Grokking the Coding Interview: Patterns for Coding Questions</p>
                                    </div>
                                    <div className="col-1 p-0">
                                        <p>Free</p>
                                    </div>
                                </div>
                            </NavLink>
                            <br />
                            <NavLink to="/designgurus/grokking-the-system-design-interview">
                                <div className="row">
                                    <div className="col-5">
                                        <LazyLoadImage effect="blur" src={course2} className="card-img-top" style={{ width: '100%' }} alt="course2" />
                                    </div>
                                    <div className="col-6 p-0">
                                        <p>Grokking the System Design Interview</p>
                                    </div>
                                    <div className="col-1 p-0">
                                        <p>Free</p>
                                    </div>
                                </div>
                            </NavLink>
                            <br />
                            <NavLink to="/designgurus/grokking-dynamic-programming-patterns-for-coding-interviews">
                                <div className="row">
                                    <div className="col-5">
                                        <LazyLoadImage effect="blur" src={course4} className="card-img-top" style={{ width: '100%' }} alt="course4" />
                                    </div>
                                    <div className="col-6 p-0">
                                        <p>Grokking Dynamic Programming Patterns for Coding Interviews</p>
                                    </div>
                                    <div className="col-1 p-0">
                                        <p>Free</p>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <br />
            </>
        )
    } else if (parameter.parameter == "buy-both-system-design-courses") {
        return (
            <>
            <LoadingBar color='orangered' progress={100} height={5} />
                <Helmet>
                    <title>{parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')}  {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mb-md-0 mb-5">
                            <p>OUR GREAT OFFER</p>
                            <h1 className="learnworlds-subheading-large">Best of System Design</h1>
                            <br />
                            <p>Buy both system design courses by Coding Courses Hub to save 10% and have lifetime access.</p>
                            <br />
                            <div className="row justify-content-around">
                                <div className="col-3 border text-center" style={{ backgroundColor: 'whitesmoke' }}>
                                    <span>2</span>
                                    <p>Courses</p>
                                </div>
                                <div className="col-3 border text-center" style={{ backgroundColor: 'whitesmoke' }}>
                                    <span>10%</span>
                                    <p>Discount</p>
                                </div>
                                <div className="col-3 border text-center" style={{ backgroundColor: 'whitesmoke' }}>
                                    <span>$16</span>
                                    <p>You Save</p>
                                </div>
                            </div>
                            <br />
                            <br />
                            <button className="btn btn-danger">Buy now <del>$158</del> $142</button>
                            <br />
                            <br />
                            <br />
                            <h3 className="learnworlds-subheading-large">Best of System Design</h3>
                            <br />
                            <button className="btn btn-danger">Buy now <del>$158</del> $142</button>
                        </div>
                        <div className="col-md-4 mt-auto">
                            <LazyLoadImage effect="blur" src={bundle2} className="card-img-top" style={{ width: '100%', height: '174px' }} alt="bundle2" />
                            <br />
                            <br />
                            <NavLink to="/designgurus/grokking-the-advanced-system-design-interview">
                                <div className="row">
                                    <div className="col-5">
                                        <LazyLoadImage effect="blur" src={course3} className="card-img-top" style={{ width: '100%' }} alt="course3" />
                                    </div>
                                    <div className="col-6 p-0">
                                        <p>Grokking the Advanced System Design Interview</p>
                                    </div>
                                    <div className="col-1 p-0">
                                        <p>Free</p>
                                    </div>
                                </div>
                            </NavLink>
                            <br />
                            <NavLink to="/designgurus/grokking-the-system-design-interview">
                                <div className="row">
                                    <div className="col-5">
                                        <LazyLoadImage effect="blur" src={course2} className="card-img-top" style={{ width: '100%' }} alt="course2" />
                                    </div>
                                    <div className="col-6 p-0">
                                        <p>Grokking the System Design Interview</p>
                                    </div>
                                    <div className="col-1 p-0">
                                        <p>Free</p>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <br />
            </>
        )
    } else if (parameter.parameter == "frequently-bought-together") {
        return (
            <>
            <LoadingBar color='orangered' progress={100} height={5} />
                <Helmet>
                    <title>{parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')}  {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mb-md-0 mb-5">
                            <p>OUR GREAT OFFER</p>
                            <h1 className="learnworlds-subheading-large">Frequently Bought Together</h1>
                            <br />
                            <p>Buy the two courses frequently bought together to get 10% off and have lifetime access.</p>
                            <br />
                            <div className="row justify-content-around">
                                <div className="col-3 border text-center" style={{ backgroundColor: 'whitesmoke' }}>
                                    <span>2</span>
                                    <p>Courses</p>
                                </div>
                                <div className="col-3 border text-center" style={{ backgroundColor: 'whitesmoke' }}>
                                    <span>10%</span>
                                    <p>Discount</p>
                                </div>
                                <div className="col-3 border text-center" style={{ backgroundColor: 'whitesmoke' }}>
                                    <span>$16</span>
                                    <p>You Save</p>
                                </div>
                            </div>
                            <br />
                            <br />
                            <button className="btn btn-danger">Buy now <del>$158</del> $142</button>
                            <br />
                            <br />
                            <br />
                            <h3 className="learnworlds-subheading-large">Frequently Bought Together</h3>
                            <br />
                            <button className="btn btn-danger">Buy now <del>$158</del> $142</button>
                        </div>
                        <div className="col-md-4 mt-auto">
                            <LazyLoadImage effect="blur" src={bundle4} className="card-img-top" style={{ width: '100%', height: '174px' }} alt="bundle4" />
                            <br />
                            <br />
                            <NavLink to="/designgurus/grokking-the-system-design-interview">
                                <div className="row">
                                    <div className="col-5">
                                        <LazyLoadImage effect="blur" src={course2} className="card-img-top" style={{ width: '100%' }} alt="course2" />
                                    </div>
                                    <div className="col-6 p-0">
                                        <p>Grokking the System Design Interview</p>
                                    </div>
                                    <div className="col-1 p-0">
                                        <p>Free</p>
                                    </div>
                                </div>
                            </NavLink>
                            <br />
                            <NavLink to="/designgurus/grokking-the-coding-interview-patterns-for-coding-questions">
                                <div className="row">
                                    <div className="col-5">
                                        <LazyLoadImage effect="blur" src={course1} className="card-img-top" style={{ width: '100%' }} alt="course1" />
                                    </div>
                                    <div className="col-6 p-0">
                                        <p>Grokking the Coding Interview: Patterns for Coding Questions</p>
                                    </div>
                                    <div className="col-1 p-0">
                                        <p>Free</p>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <br />
            </>
        )
    } else if (parameter.parameter == "both-coding-course") {
        return (
            <>
            <LoadingBar color='orangered' progress={100} height={5} />
                <Helmet>
                    <title>{parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')}  {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mb-md-0 mb-5">
                            <p>OUR GREAT OFFER</p>
                            <h1 className="learnworlds-subheading-large">Both Coding Course</h1>
                            <br />
                            <p>Buy both coding courses by Coding Courses Hub to save 10% and have lifetime access.</p>
                            <br />
                            <div className="row justify-content-around">
                                <div className="col-3 border text-center" style={{ backgroundColor: 'whitesmoke' }}>
                                    <span>2</span>
                                    <p>Courses</p>
                                </div>
                                <div className="col-3 border text-center" style={{ backgroundColor: 'whitesmoke' }}>
                                    <span>10%</span>
                                    <p>Discount</p>
                                </div>
                                <div className="col-3 border text-center" style={{ backgroundColor: 'whitesmoke' }}>
                                    <span>$13</span>
                                    <p>You Save</p>
                                </div>
                            </div>
                            <br />
                            <br />
                            <button className="btn btn-danger">Buy now <del>$128</del> $115</button>
                            <br />
                            <br />
                            <br />
                            <h3 className="learnworlds-subheading-large">Both Coding Course</h3>
                            <br />
                            <button className="btn btn-danger">Buy now <del>$128</del> $115</button>
                        </div>
                        <div className="col-md-4 mt-auto">
                            <LazyLoadImage effect="blur" src={bundle3} className="card-img-top" style={{ width: '100%', height: '174px' }} alt="bundle3" />
                            <br />
                            <br />
                            <NavLink to={`/courses/grokking-dynamic-programming-patterns-for-coding-interviews`}>
                                <div className="row">
                                    <div className="col-5">
                                        <LazyLoadImage effect="blur" src={course4} className="card-img-top" style={{ width: '100%' }} alt="course4" />
                                    </div>
                                    <div className="col-6 p-0">
                                        <p>Grokking Dynamic Programming Patterns for Coding Interviews</p>
                                    </div>
                                    <div className="col-1 p-0">
                                        <p>Free</p>
                                    </div>
                                </div>
                            </NavLink>
                            <br />
                            <NavLink to={`/courses/grokking-the-coding-interview-patterns-for-coding-questions`}>
                                <div className="row">
                                    <div className="col-5">
                                        <LazyLoadImage effect="blur" src={course1} className="card-img-top" style={{ width: '100%' }} alt="course1" />
                                    </div>
                                    <div className="col-6 p-0">
                                        <p>Grokking the Coding Interview: Patterns for Coding Questions</p>
                                    </div>
                                    <div className="col-1 p-0">
                                        <p>Free</p>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <br />
            </>
        )
    } else if (parameter.parameter == "all-courses") {
        return (
            <>
            <LoadingBar color='orangered' progress={100} height={5} />
                <Helmet>
                    <title>{parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')}  {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mb-md-0 mb-5">
                            <p>OUR GREAT OFFER</p>
                            <h1 className="learnworlds-subheading-large">All Courses</h1>
                            <br />
                            <p>Buy all five courses from Coding Courses Hub to Save 30% and have lifetime access.</p>
                            <br />
                            <div className="row justify-content-around">
                                <div className="col-3 border text-center" style={{ backgroundColor: 'whitesmoke' }}>
                                    <span>5</span>
                                    <p>Courses</p>
                                </div>
                                <div className="col-3 border text-center" style={{ backgroundColor: 'whitesmoke' }}>
                                    <span>30%</span>
                                    <p>Discount</p>
                                </div>
                                <div className="col-3 border text-center" style={{ backgroundColor: 'whitesmoke' }}>
                                    <span>$106</span>
                                    <p>You Save</p>
                                </div>
                            </div>
                            <br />
                            <br />
                            <button className="btn btn-danger">Buy now <del>$345</del> $239</button>
                            <br />
                            <br />
                            <br />
                            <h3 className="learnworlds-subheading-large">All Coding Courses Hub Courses</h3>
                            <br />
                            <button className="btn btn-danger">Buy now <del>$345</del> $239</button>
                        </div>
                        <div className="col-md-4 mt-auto">
                            <LazyLoadImage effect="blur" src={bundle1} className="card-img-top" style={{ width: '100%', height: '174px' }} alt="bundle1" />
                            <br />
                            <br />
                            <NavLink to="/designgurus/grokking-dynamic-programming-patterns-for-coding-interviews">
                                <div className="row">
                                    <div className="col-5">
                                        <LazyLoadImage effect="blur" src={course4} className="card-img-top" style={{ width: '100%' }} alt="course4" />
                                    </div>
                                    <div className="col-6 p-0">
                                        <p>Grokking Dynamic Programming Patterns for Coding Interviews</p>
                                    </div>
                                    <div className="col-1 p-0">
                                        <p>Free</p>
                                    </div>
                                </div>
                            </NavLink>
                            <br />
                            <NavLink to="/designgurus/grokking-the-advanced-system-design-interview">
                                <div className="row">
                                    <div className="col-5">
                                        <LazyLoadImage effect="blur" src={course3} className="card-img-top" style={{ width: '100%' }} alt="course3" />
                                    </div>
                                    <div className="col-6 p-0">
                                        <p>Grokking the Advanced System Design Interview</p>
                                    </div>
                                    <div className="col-1 p-0">
                                        <p>Free</p>
                                    </div>
                                </div>
                            </NavLink>
                            <br />
                            <NavLink to="/designgurus/grokking-the-coding-interview-patterns-for-coding-questions">
                                <div className="row">
                                    <div className="col-5">
                                        <LazyLoadImage effect="blur" src={course1} className="card-img-top" style={{ width: '100%' }} alt="course1" />
                                    </div>
                                    <div className="col-6 p-0">
                                        <p>Grokking the Coding Interview: Patterns for Coding Questions</p>
                                    </div>
                                    <div className="col-1 p-0">
                                        <p>Free</p>
                                    </div>
                                </div>
                            </NavLink>
                            <br />
                            <NavLink to="/designgurus/grokking-the-system-design-interview">
                                <div className="row">
                                    <div className="col-5">
                                        <LazyLoadImage effect="blur" src={course2} className="card-img-top" style={{ width: '100%' }} alt="course2" />
                                    </div>
                                    <div className="col-6 p-0">
                                        <p>Grokking the System Design Interview</p>
                                    </div>
                                    <div className="col-1 p-0">
                                        <p>Free</p>
                                    </div>
                                </div>
                            </NavLink>
                            <br />
                            <NavLink to="/designgurus/grokking-the-object-oriented-design-interview">
                                <div className="row">
                                    <div className="col-5">
                                        <LazyLoadImage effect="blur" src={course5} className="card-img-top" style={{ width: '100%' }} alt="course5" />
                                    </div>
                                    <div className="col-6 p-0">
                                        <p>Grokking the Object Oriented Design Interview</p>
                                    </div>
                                    <div className="col-1 p-0">
                                        <p>Free</p>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <br />
            </>
        )
    } else if (parameter.parameter == "tech-design-interviews") {
        return (
            <>
            <LoadingBar color='orangered' progress={100} height={5} />
                <Helmet>
                    <title>{parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')}  {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mb-md-0 mb-5">
                            <p>OUR GREAT OFFER</p>
                            <h1 className="learnworlds-subheading-large">Tech Design Interviews</h1>
                            <br />
                            <p>Buy all five courses from Coding Courses Hub to Save 30% and have lifetime access.</p>
                            <br />
                            <div className="row justify-content-around">
                                <div className="col-3 border text-center" style={{ backgroundColor: 'whitesmoke' }}>
                                    <span>2</span>
                                    <p>Courses</p>
                                </div>
                                <div className="col-3 border text-center" style={{ backgroundColor: 'whitesmoke' }}>
                                    <span>10%</span>
                                    <p>Discount</p>
                                </div>
                                <div className="col-3 border text-center" style={{ backgroundColor: 'whitesmoke' }}>
                                    <span>$14</span>
                                    <p>You Save</p>
                                </div>
                            </div>
                            <br />
                            <br />
                            <button className="btn btn-danger">Buy now <del>$138</del> $124</button>
                            <br />
                            <br />
                            <br />
                            <h3 className="learnworlds-subheading-large">Tech Design Interviews</h3>
                            <br />
                            <button className="btn btn-danger">Buy now <del>$138</del> $124</button>
                        </div>
                        <div className="col-md-4 mt-auto">
                            <LazyLoadImage effect="blur" src={bundle5} className="card-img-top" style={{ width: '100%', height: '174px' }} alt="bundle5" />
                            <br />
                            <br />
                            <NavLink to="/designgurus/grokking-the-system-design-interview">
                                <div className="row">
                                    <div className="col-5">
                                        <LazyLoadImage effect="blur" src={course2} className="card-img-top" style={{ width: '100%' }} alt="course2" />
                                    </div>
                                    <div className="col-6 p-0">
                                        <p>Grokking the System Design Interview</p>
                                    </div>
                                    <div className="col-1 p-0">
                                        <p>Free</p>
                                    </div>
                                </div>
                            </NavLink>
                            <br />
                            <NavLink to="/designgurus/grokking-the-object-oriented-design-interview">
                                <div className="row">
                                    <div className="col-5">
                                        <LazyLoadImage effect="blur" src={course5} className="card-img-top" style={{ width: '100%' }} alt="course5" />
                                    </div>
                                    <div className="col-6 p-0">
                                        <p>Grokking the Object Oriented Design Interview</p>
                                    </div>
                                    <div className="col-1 p-0">
                                        <p>Free</p>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <br />
            </>
        )
    } else if (parameter.parameter == "all-design-courses") {
        return (
            <>
            <LoadingBar color='orangered' progress={100} height={5} />
                <Helmet>
                    <title>{parameter.parameter.replace(/-/g, ' ').replace(/\b./g, function (m) { return m.toUpperCase() }).replace(',', '')}  {year}</title>
                    <link rel="canonical" href={window.location.href} />
                </Helmet>
                <br />
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 mb-md-0 mb-5">
                            <p>OUR GREAT OFFER</p>
                            <h1 className="learnworlds-subheading-large">All Design Courses</h1>
                            <br />
                            <p>Buy all design courses together to save 25% and have lifetime access.</p>
                            <br />
                            <div className="row justify-content-around">
                                <div className="col-3 border text-center" style={{ backgroundColor: 'whitesmoke' }}>
                                    <span>3</span>
                                    <p>Courses</p>
                                </div>
                                <div className="col-3 border text-center" style={{ backgroundColor: 'whitesmoke' }}>
                                    <span>25%</span>
                                    <p>Discount</p>
                                </div>
                                <div className="col-3 border text-center" style={{ backgroundColor: 'whitesmoke' }}>
                                    <span>$54.25</span>
                                    <p>You Save</p>
                                </div>
                            </div>
                            <br />
                            <br />
                            <button className="btn btn-danger">Buy now <del>$217</del> $162.75</button>
                            <br />
                            <br />
                            <br />
                            <h3 className="learnworlds-subheading-large">All Design Courses</h3>
                            <br />
                            <button className="btn btn-danger">Buy now <del>$217</del> $162.75</button>
                        </div>
                        <div className="col-md-4 mt-auto">
                            <LazyLoadImage effect="blur" src={bundle1} className="card-img-top" style={{ width: '100%', height: '174px' }} alt="bundle1" />
                            <br />
                            <br />
                            <NavLink to="/designgurus/grokking-the-advanced-system-design-interview">
                                <div className="row">
                                    <div className="col-5">
                                        <LazyLoadImage effect="blur" src={course3} className="card-img-top" style={{ width: '100%' }} alt="course3" />
                                    </div>
                                    <div className="col-6 p-0">
                                        <p>Grokking the Advanced System Design Interview</p>
                                    </div>
                                    <div className="col-1 p-0">
                                        <p>Free</p>
                                    </div>
                                </div>
                            </NavLink>
                            <br />
                            <NavLink to="/designgurus/grokking-the-object-oriented-design-interview">
                                <div className="row">
                                    <div className="col-5">
                                        <LazyLoadImage effect="blur" src={course5} className="card-img-top" style={{ width: '100%' }} alt="course5" />
                                    </div>
                                    <div className="col-6 p-0">
                                        <p>Grokking the Object Oriented Design Interview</p>
                                    </div>
                                    <div className="col-1 p-0">
                                        <p>Free</p>
                                    </div>
                                </div>
                            </NavLink>
                            <br />
                            <NavLink to="/designgurus/grokking-the-system-design-interview">
                                <div className="row">
                                    <div className="col-5">
                                        <LazyLoadImage effect="blur" src={course2} className="card-img-top" style={{ width: '100%' }} alt="course2" />
                                    </div>
                                    <div className="col-6 p-0">
                                        <p>Grokking the System Design Interview</p>
                                    </div>
                                    <div className="col-1 p-0">
                                        <p>Free</p>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <br />
            </>
        )
    } else {
        window.location.href = '/'
        // return (
        //     <>
        //         <Helmet>
        //             <title>404 Page Not Found  {year}</title>
        //             <link rel="canonical" href={window.location.href} />
        //         </Helmet>
        //         <br />
        //         <div className="container">
        //             <div className="row justify-content-center">
        //                 <LazyLoadImage effect="blur" src={_404Error} style={{ width: '10%' }} />
        //                 <div className="col-12 text-center">
        //                     <h1 style={{ fontSize: '50px' }}>404 Page Not Found</h1>
        //                 </div>
        //             </div>
        //         </div>
        //         <br />
        //     </>
        // )
    }
}