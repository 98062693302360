import React from 'react'
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import './Coupons.css';
import educative from '../images/educative.png';
import nasco from '../images/nasco.png';
import slumberKins from '../images/slumberKins.png';
import hooked from '../images/hooked.png';
import science from '../images/science.png';
import mcKissock from '../images/mcKissock.png';
import nest from '../images/nest.png';
import nitro from '../images/nitro.png';
import genius from '../images/genius.png';
import hero from '../images/hero.png';
import LoadingBar from 'react-top-loading-bar';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function Coupons() {
    const d = new Date();
    let year = d.getFullYear().toString();

    var currentUrl = window.location.href;
    var lastElement = currentUrl[currentUrl.length - 1];
    if (lastElement != "/") {
        window.history.pushState({}, null, currentUrl + "/");
    }

    return (
        <>
        <LoadingBar color='orangered' progress={100} height={5} />
            <Helmet>
                <title>Coupon Codes, Coupons, Promo Codes, Deals and Discounts</title>
                <meta name="description" content={`Coupons Code And Promos`} />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <br />
            <div className="container" id='coupons'>
                <div className="row justify-content-center">
                    <div className="col-md-3 mt-4">
                        <NavLink to="/coupons/educative">
                            <div className="card">
                                <LazyLoadImage effect="blur" src={educative} className="card-img-top" alt="educative" />
                                <hr />
                                <div className="card-body">
                                    <h5 className="card-title text-center">Educative.io</h5>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-md-3 mt-4">
                        <NavLink to="/coupons/nasco">
                            <div className="card">
                                <LazyLoadImage effect="blur" src={nasco} className="card-img-top" alt="nasco" />
                                <hr />
                                <div className="card-body">
                                    <h5 className="card-title text-center">Nasco</h5>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-md-3 mt-4">
                        <NavLink to="/coupons/slumberkins">
                            <div className="card">
                                <LazyLoadImage effect="blur" src={slumberKins} className="card-img-top" alt="slumberKins" />
                                <hr />
                                <div className="card-body">
                                    <h5 className="card-title text-center">SlumberKins</h5>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-md-3 mt-4">
                        <NavLink to="/coupons/hooked-on-phonics">
                            <div className="card mx-auto">
                                <LazyLoadImage effect="blur" src={hooked} className="card-img-top" alt="hooked" />
                                <hr />
                                <div className="card-body">
                                    <h5 className="card-title text-center">Hooked On Phonics</h5>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-md-3 mt-4">
                        <NavLink to="/coupons/mel-chemistry">
                            <div className="card">
                                <LazyLoadImage effect="blur" src={science} className="card-img-top" alt="science" />
                                <hr />
                                <div className="card-body">
                                    <h5 className="card-title text-center">Mel Science</h5>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-md-3 mt-4">
                        <NavLink to="/coupons/mckissock">
                            <div className="card">
                                <LazyLoadImage effect="blur" src={mcKissock} className="card-img-top" alt="mcKissock" />
                                <hr />
                                <div className="card-body">
                                    <h5 className="card-title text-center">McKissock</h5>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-md-3 mt-4">
                        <NavLink to="/coupons/nestlearning">
                            <div className="card">
                                <LazyLoadImage effect="blur" src={nest} className="card-img-top" alt="nest" />
                                <hr />
                                <div className="card-body">
                                    <h5 className="card-title text-center">Nest Learning</h5>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-md-3 mt-4">
                        <NavLink to="/coupons/nitro-college">
                            <div className="card">
                                <LazyLoadImage effect="blur" src={nitro} className="card-img-top" alt="nitro" />
                                <hr />
                                <div className="card-body">
                                    <h5 className="card-title text-center">Nitro College</h5>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-md-3 mt-4">
                        <NavLink to="/coupons/genius-one">
                            <div className="card">
                                <LazyLoadImage effect="blur" src={genius} className="card-img-top" alt="genius" />
                                <hr />
                                <div className="card-body">
                                    <h5 className="card-title text-center">Genius One</h5>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                    <div className="col-md-3 mt-4">
                        <NavLink to="/coupons/course-hero">
                            <div className="card">
                                <LazyLoadImage effect="blur" src={hero} className="card-img-top" alt="hero" />
                                <hr />
                                <div className="card-body">
                                    <h5 className="card-title text-center">Course Hero</h5>
                                </div>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>
            <br /><br /><hr />
            <br />
            <div className='container'>
                <div className='row'>
                    <p className='text-justify'>
                        <ul>
                            <li>What is a promo code?</li>
                        </ul>
                        A promo code is a string of characters or a combination of letters and numbers that can be entered at checkout to receive a discount or special offer on a purchase. They are often used for promotions, sales, and special deals. Promo codes can be found on a company's website or through marketing emails and can be applied to online or in-store purchases.
                        <br /><br />
                        <ul>
                            <li>Why should I use a promo code?</li>
                        </ul>
                        Promo codes can be a great way to save money on your purchase. They can offer discounts such as a percentage off the total purchase price or a fixed dollar amount. Some promo codes can even offer free shipping or a free gift with purchase. Additionally, some companies offer exclusive deals or early access to sales to customers who use a promo code. Using a promo code can be a great way to save money and get access to special deals and promotions.
                    </p>
                </div>
            </div>
            <br /><br />
        </>
    )
}